import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Hidden, Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import DashboardIcon from '@material-ui/icons/Dashboard';

const NavBar = props => {
    const { classes, mobileOpen, onMobileClose } = props;
    const [user, setUser] = useState(props.user);

    useEffect(()=>{
        if(user !== props.user){
        applyAuths();
        }
    })

    const applyAuths = () => {
        setUser(props.user);
    }

    const routeLink = {
        "dashboard": {
            component: { key: "dashboard", link: "/", linkIcon: <DashboardIcon />, linkText: "Dashboard" }
            ,order: 10
        }
        ,"saalert": {
            component: { key: "saalert", link: "/saalert", linkIcon: <DescriptionIcon />, linkText: "Super Admin Alert" }
            ,order: 20
        }
        ,"sachart": {
            component: { key: "sachart", link: "/sachart", linkIcon: <DescriptionIcon />, linkText: "Super Admin Chart" }
            ,order: 25
        }
        ,"accounts": {
            component: { key: "account", link: "/accounts", linkIcon: <DescriptionIcon />, linkText: "Account" }
            ,order: 30
        }
        ,"export": {
            component: { key: "exportData", link: "/export", linkIcon: <DescriptionIcon />, linkText: "Export Data" }
            ,order: 40
        }
        ,"survey": {
            component: { key: "survey", link: "/survey", linkIcon: <DescriptionIcon />, linkText: "Survey" }
            ,order: 50
        }
    }

    const accessibleFunc = (() => {
        let accessibleFunc = [];
        user.aPortalFunc.forEach(func => {
            if (Object.keys(routeLink).includes(func)) {
                accessibleFunc.push(func);
            }
        })
        return accessibleFunc
    })();

    const menu = (["dashboard"].concat(accessibleFunc))
    .sort((a,b) => routeLink[a]["order"] - routeLink[b]["order"])
    .map(key => routeLink[key]["component"]);

    const listItems = menu.map(item => <ListItem
        key={item.key}
        button
        onClick={() => {
            props.history.push(item.link);
            onMobileClose();
        }}
        >
        <ListItemIcon>
            {item.linkIcon}
        </ListItemIcon>
        <ListItemText>{item.linkText}</ListItemText>
        </ListItem>)
    
    return (
        <Fragment>
            <Hidden mdDown implementation="css">
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                    paper: classes.drawerPaper
                    }}
                    anchor="left"
                >
                    <List>{listItems}</List>
                </Drawer>
            </Hidden>

            <Hidden smUp implementation="css">
                <Drawer
                    className={classes.drawer}
                    variant="temporary"
                    classes={{
                    paper: classes.drawerPaper
                    }}
                    anchor="left"
                    open={mobileOpen}
                    onClose={onMobileClose}
                >
                    <List>{listItems}</List>
                </Drawer>
            </Hidden>
        </Fragment>
    )
}


const mapStateToProps = state => {
  return {
    staff: state.system.staff,
    selectedProductId: state.system.selectedProductId,
    products: state.system.products
  };
};

const drawerWidth = 200;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 64
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
});

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(NavBar)));
