import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Paper, FormControl, FormHelperText, Dialog, TextField, Grid, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';

import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';

const DeviceLocationEditDialog = props => {
  const { device, open, onBeforeCloseHandler, client } = props;

  const [error, setError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [floorError, setFloorError] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [location, setLocation] = useState(device.location);
  const [name, setName] = useState(device.shk_name);
  const [floor, setFloor] = useState(device.shk_floor);
  const [sector, setSector] = useState(device.shk_sector);

  const onSaveButtonHandler = async () => {
    if (client !== 'shk'){
      if (!location || location === '') {
        setError('Please Input the Location.')
        return;
      }
    }
    else{
      if (!name || name === '') {
        setNameError('Please Input the Name.')
        return;
      }
      if (!floor || floor === '') {
        setFloorError('Please Input the Floor.')
        return;
      }
      if (!sector || sector === '') {
        setLocationError('Please Input the Sector.')
        return;
      }
    }
    let prefix = client === 'shk' ? client : undefined;

    let updateLocationApiResult = await api('post', `${envars.deviceServiceUrl}/update/${device.deviceId}/location`, { location, prefix, name, floor, sector });
    if (updateLocationApiResult.data.success) {
      props.makeSnackbar('Device Location Updated')
      let updatedDevice = updateLocationApiResult.data.result.device;
      updatedDevice.email = device.email;
      onBeforeCloseHandler(updatedDevice);
    } else {
      handleApiFailureWithDialog(props.requestDialog, updateLocationApiResult);
    }
  }

  return (
    <Dialog open={open} onClose={() => { onBeforeCloseHandler() }}>
      <div className="edit-dialog paper-with_padding">
        <Grid>
          <Grid item>
            <FormControl margin="normal" fullWidth required>
              { client !== 'shk' ?
              <TextField
                id="deviceLocation"
                label="Edit Device Location"
                value={location}
                onChange={(e) => {
                  setError(null);
                  setLocation(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
                error={error ? true : false}
                helpertext={error ? error : null}
              />
              :
              <>
              <TextField
                id="deviceName"
                label="Edit Device Name"
                value={name}
                onChange={(e) => {
                  setNameError(null);
                  setName(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
                error={nameError ? true : false}
                helpertext={nameError ? nameError : null}
              />
              <TextField
                id="deviceFloor"
                label="Edit Device Floor"
                value={floor}
                onChange={(e) => {
                  setFloorError(null);
                  setFloor(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
                error={floorError ? true : false}
                helpertext={floorError ? floorError : null}
              />
              <TextField
                id="deviceSector"
                label="Edit Device Sector"
                value={sector}
                onChange={(e) => {
                  setLocationError(null);
                  setSector(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
                error={locationError ? true : false}
                helpertext={locationError ? locationError : null}
              />
              </>
              }
              {/* <FormHelperText id="errorName" required error={error ? true : false} style={{ display: 'none' }}>
            Please Input Creative Name
          </FormHelperText> */}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSaveButtonHandler}
            >
              <SaveIcon />
                Save
              </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(DeviceLocationEditDialog)));