import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid, Paper, Typography, TextField, TablePagination, InputAdornment, FormControl, InputLabel, Select, MenuItem, Button
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import SubjectIcon from '@material-ui/icons/Subject';

import PageLoadingView from '../../components/PageLoadingView/PageLoadingView';
import PageErrorView from '../../components/PageErrorView/PageErrorView';
import AccountCard from '../../components/AccountCard/AccountCard';

import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';
import useDebounce from '../../utils/useDebounce';
import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';
import AccountAddDrawer from '../../containers/AccountAddDrawer/AccountAddDrawer';
import AccountConfigDrawer from '../../containers/AccountConfigDrawer/AccountConfigDrawer';

const DashboardPage = props => {
  const [loaded, setLoaded] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  // should delete, old variable from dashboard page
  const [selectedId, setSelectedId] = useState('');

  const [selectedEmail, setSelectedEmail] = useState('');

  const [addingNewAccount, setAddingNewAccount] = useState(false);

  const [addingNewConfig, setAddingNewConfig] = useState(false);

  const debouncedSelectedEmail = useDebounce(selectedEmail, 200);

  useEffect(() => {
    if (!loaded) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, debouncedSelectedEmail]);

  const fetchData = _.debounce(async () => {
    setLoaded(false);
    // let querys = [];
    // querys.push(`page=${page}`);
    // querys.push(`size=${rowsPerPage}`);
    let accountApiResult = await api('get', `${envars.userServiceUrl}/accounts`, null);
    if (accountApiResult.data.success) {
      setCount(accountApiResult.data.result.count);

      let accounts = accountApiResult.data.result.accounts;
      
      //let userfilterAccounts = accounts.filter(d => d.email.includes("shk"));

      let filterAccounts = filterData(accounts);
      filterAccounts = filterAccounts.filter(a => a.deleted === undefined || a.deleted === 0);
      

      setAccounts(filterAccounts);
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, accountApiResult);
    }
  }, 500);

  const filterData = (data) => {
    let filterData = [...data];

    //filterData = filterData.filter(d => d.email.includes("shk"));

    if (debouncedSelectedEmail !== "") {
      filterData = filterData.filter(d => d.email.includes(debouncedSelectedEmail));
    }

    filterData = filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return filterData;
  }

  const onPageChangeHandler = (e, pageStartsFromZero) => {
    setPage(pageStartsFromZero);
  };

  const onRowsPerPageChangeHandler = (e) => {
    setPage(0);
    setRowsPerPage(e.target.value);
  };

  const onSelectedEmailChanged = (e) => {
    setPage(0);
    setSelectedEmail(e.target.value);
  }

  // if (!loaded) {
  //   return <PageLoadingView />;
  // }

  return (
    <div className="paper-with-padding">
      <Grid container spacing={1} justify="flex-start">
        <Grid item>
          <Typography variant="h4" component="h2">
            Account
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => { setAddingNewAccount(true) }}
            color="secondary"
            variant="contained"
          >
            <AddIcon />
            Add New
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <Paper className="paper-with-padding">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth >
                  <TextField
                    label="Search By Account Email"
                    margin="normal"
                    value={selectedEmail}
                    fullWidth
                    onChange={onSelectedEmailChanged}
                    // onChange= {ev => onKeywordChanged(ev.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs>
          <Paper className="paper-with-padding">
            <Grid item xs>
              <Typography variant="body1" component="h2">
                <SubjectIcon />
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              {!loaded ? (
                <Grid item xs={12}>
                  <PageLoadingView />
                </Grid>
              ) : (
                  accounts.map((account, i) => {
                    return (
                      <Grid item xs={12} sm={4} key={`account-grid-${i}`} className="accountCardContainer" margin='normal'>
                        <AccountCard account={account} onClickHandler={(e) => {
                          if (e.ctrlKey) {
                            const win = window.open(`/account/${account.email}`, '_blank');
                            win.blur();
                            win.focus();
                          } else {
                            props.history.push(`/account/${account.email}`);
                          }
                        }} />
                      </Grid>
                    )
                  })
                )}
            </Grid>
            <Grid item xs>
              <TablePagination
                rowsPerPageOptions={[30, 60, 90]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page'
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page'
                }}
                onChangePage={onPageChangeHandler}
                onChangeRowsPerPage={onRowsPerPageChangeHandler}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <AccountAddDrawer
        open={addingNewAccount}
        onBeforeCloseHandler={(newAccount) => {
          if (newAccount) {
            fetchData();
          }
          setAddingNewAccount(false);
        }}
      />
      {/*<AccountConfigDrawer
        open={addingNewAccount}
        onBeforeCloseHandler={(newAccount) => {
          if (newAccount) {
            fetchData();
          }
          setAddingNewConfig(false);
        }}
      />*/}
    </div>
  )
}


const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(DashboardPage)));
