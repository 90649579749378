import React from 'react';
import _ from 'lodash';
import {
  Button, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Avatar
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import GetAppIcon from '@material-ui/icons/GetApp';
  
  const FileItem = (fileName, url) => {
    return (
      <ListItem alignItems='center'>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={fileName}
        />
        <ListItemSecondaryAction>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }}>
            <GetAppIcon />
          </Button>
        </ListItemSecondaryAction>
      </ListItem >
    )
  }

export default FileItem;