import React, { Component, useState, useEffect } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import SubjectIcon from '@material-ui/icons/Subject';
import moment from "moment";

import PageLoadingView from '../../../components/PageLoadingView/PageLoadingView';
import CustomizedTable from '../../../components/CustomizedTable/CustomizedTable';

const titleDict = {
    "temperature": "Temperature on board (°C)",
    "humidity": "Relative Humidity (%)",
    "co2": "CO2 (ppm)",
    "voc": "TVOC (ppb)",
    "pm10": "PM 10 (μg/m3)",
    "pm25": "PM 2.5 (μg/m3)",
    "AirTemperature": "Air Temperature (°C)",
    "AirVelocity": "Air Velocity (m/s)",
    "RadTemperature": "Radiant Temperature (°C)",
    "SoundLevel": "Sound Level (dB)",
    "light": "Light (lm)"
}

const idLabelDict = {
    "timestamp": "Timestamp",
    "deviceId": "Device ID",
    "location": "Location",
    "qualityAcceptance": "Q1",
    "thermalSensation": "Q2",
    "threeAural": "Q3 Aural",
    "threeVisual": "Q3 Visual",
    "threeOverall": "Q3 Overall",
    "fourAirQuality": "Q4 Air Quality",
    "fourThermal": "Q4 Thermal",
    "fourAural": "Q4 Aural",
    "fourVisual": "Q4 Visual",
    "fourOverall": "Q4 Overall",
    ...titleDict
}

const idFuncFunction = (key) => {
    switch (key) {
        case "timestamp": 
            return (value) => moment(new Date(value*1000)).format('YYYY-MM-DD HH:mm:ss');
        case "deviceId":
        case "threeAural":
        case "threeVisual":
        case "threeOverall":
            return (value) => value;
        case "qualityAcceptance": return (value) => {
            switch (value) {
                case 1: return "Very Bad";
                case 2: return "Bad";
                case 3: return "Neutral";
                case 4: return "Good";
                case 5: return "Very Good";
            }
        };
        case "thermalSensation": return (value) => {
            switch (value) {
                case 1: return "Cold";
                case 2: return "Cool";
                case 3: return "Slightly cool";
                case 4: return "Optimal";
                case 5: return "Slightly warm";
                case 6: return "Warm";
                case 7: return "Hot";
            }
        };
        
        case "fourAirQuality":
        case "fourThermal":
        case "fourAural":
        case "fourVisual":
        case "fourOverall":
            return (value) => value === 1 ? "Yes" : value === 2 ? "No": "Not sure";

        default:
            return (value) => {
              switch(typeof(value)) {
                case "number":
                  return value.toFixed(2);
                case "string":
                  return value;
              }
            };
    }
}

// const TABLE_COLUMNS = ([
//   {
//     minWidth: 30, id: 'timestamp', label: 'Timestamp',
//   },
//   {
//     minWidth: 30, id: 'hcn', label: 'HCN\u00A0(-)',
//     format: (value) => parseFloat(value.toFixed(4))
//   }
// ])


export {idLabelDict, idFuncFunction};
export default (props) => {
  const { data, loaded, client } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const columns = client && client !== "shk"
                ? Object.keys(idLabelDict).filter(key => data.length > 0 ? Object.keys(data[0]).includes(key) : true).map(key => { return { minWidth:30, id: key, label: idLabelDict[key], format: idFuncFunction(key)} })
                : Object.keys(idLabelDict).map(key => { return { minWidth:30, id: key, label: idLabelDict[key], format: idFuncFunction(key)} });
  
    if (!client) {
        columns.splice(1, 0, { minWidth:30, id: "client", label: "Client", format: idFuncFunction("client")});
    }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if(!loaded){
    return <PageLoadingView />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        <Paper className="paper-with-padding">
          <Grid item>
            <Typography variant="h5" component="h2">
              <SubjectIcon /> Survey Data
            </Typography>
          </Grid>
          <Grid item>
            <CustomizedTable
              name={'calibrationTable'}
              rows={data}
              columns={columns}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
};
