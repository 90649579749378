import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormControlLabel, Drawer, Grid, Paper, Typography, FormControl, TextField, InputLabel, Button, Select, MenuItem, FormHelperText, Checkbox } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';
import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';
import toHKTimeString from '../../utils/to-hk-time-string';
//import FormControlLabel from '@mui/material/FormControlLabel';
import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';

const DeviceAddDrawer = props => {
  const { open, onBeforeCloseHandler } = props;

  const [error, setError] = useState({ key: null, msg: null });

  const [account, setAccount] = useState({ 
    email: '',
    //firstName: '',
    //lastName: '',
    password: '',
    //alertEmail:'',
    client: '',
    role: 1,
    adminPortalAccess: 0,
    gdRefLine:{},
    exRefLine:{},
    graphDisplayRange: [1, 3, 8, 24],
    devices: [''],

  })

  let arr = [];
  const [deviceId, setDeviceId] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const refproperty = ["Pm10 (μg/m3)", "Pm2.5 (μg/m3)", "Co (ppm)", "Co2 (ppm)", "TVoc (ppb)", "O3 (ppm)"];
  const gdrefpropertyName = ["pm10GdRef", "pm25GdRef", "coGdRef", "co2GdRef", "vocGdRef", "ozoGdRef"];
  const exrefpropertyName = ["pm10ExRef", "pm25ExRef", "coExRef", "co2ExRef", "vocExRef", "ozoExRef"];
  const allowNull = ["adminPortalAccess", "alertEmail"];
  
  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(deviceList.map(li => li.deviceId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
    const { value, checked } = e.target;
    setIsCheck([...isCheck, value]);
    if (!checked) {
      setIsCheckAll(false);
      setIsCheck(isCheck.filter(item => item !== value));
    }
  };  
  
  const listItems = deviceList.map((device) =>
  <label style={{fontSize:16}}><input type="checkbox" id={deviceList.indexOf(device)} value={device.deviceId} 
          onChange={handleClick}
          checked={isCheck.includes(device.deviceId)}
          
          />
          {device.deviceId}
  <br/></label>
  //const listItems = numbers.map((number) =>
  //<label><input type="checkbox"/>{number.valueOf(number)}</label>
    
  );
  
  function hrsHandle(e)
  {
    const hr_arr = account.graphDisplayRange
    if (e.target.checked)
    {
      if (hr_arr.length > 0 && hr_arr.includes(e.target.value))
      {  }
      else 
      {
        hr_arr.push(Number(e.target.value))
        setAccount({...account, graphDisplayRange: hr_arr})
      }
    }
    else
    {
      if (hr_arr.length > 0)
      {
        for(let i = 0; i < hr_arr.length; i++)
        {
          if (hr_arr[i] === Number(e.target.value))
          {
            hr_arr.splice(i, 1)
            setAccount({...account, graphDisplayRange: hr_arr})
            break
          }
        }
      }
    }
    account.graphDisplayRange.sort((a, b) => { return a - b});
  }


  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = _.debounce(async () => {
    let deviceApiResult = await api('get', `${envars.deviceServiceUrl}/devices`, null);
    if (deviceApiResult.data.success) {
      let deviceList = deviceApiResult.data.result.devices;
      setDeviceList(deviceList);
      
      for (let i = 0; i < deviceList.length; i++)
      {
        arr.push(deviceList[i].deviceId)
      }
      setDeviceId(arr);

    }
  }, 500);

  const onSaveButtonHandler = async () => {
    account.devices = isCheck;
    if(props.user.role !== 3){
      account.client = props.user.client;
    }
    const newAccount = account;

    for (let i = 0; i < gdrefpropertyName.length; i++) {
      if((isNaN(account.gdRefLine[gdrefpropertyName[i]]) && account.gdRefLine[gdrefpropertyName[i]] !== undefined && account.gdRefLine[gdrefpropertyName[i]] !== '') || account.gdRefLine[gdrefpropertyName[i]] < 0){
        setError({ 'key': gdrefpropertyName[i], 'msg': gdrefpropertyName[i] + ' need to be positive number or empty' });
        return;
      }     
      if((isNaN(account.exRefLine[exrefpropertyName[i]]) && account.exRefLine[exrefpropertyName[i]] !== undefined && account.exRefLine[exrefpropertyName[i]] !== '') || account.exRefLine[exrefpropertyName[i]] < 0){
        setError({ 'key': exrefpropertyName[i], 'msg': exrefpropertyName[i] + ' need to be positive number or empty' });
        return;
      }   
    }

    for (let key of Object.keys(newAccount)) {
      if ((newAccount[key] === '' || newAccount[key] === null) && (!gdrefpropertyName.includes(key) && !exrefpropertyName.includes(key) && !allowNull.includes(key))) {
        console.log(key);
        setError({ 'key': key, 'msg': `Please Input ${key}` });
        return;
      }
    }

    setError({ key: null, msg: null });

    let addAccountApiResult = await api('post', `${envars.userServiceUrl}/account/add`, newAccount);
    if (addAccountApiResult.data.success) {
      props.makeSnackbar('Add Account Successfully.')
      let newDevice = addAccountApiResult.data.result.device;
      // console.log(newDevice);
      onBeforeCloseHandler(newDevice);
    } else {
      handleApiFailureWithDialog(props.requestDialog, addAccountApiResult);
    }


  }

  return (
    <Drawer anchor="right" open={open} onClose={() => { onBeforeCloseHandler() }}>
      <div className="drawer-on-right paper-with-padding">
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h5">New Account</Typography>
          </Grid>
          <Grid item>
            <Paper className="paper-with-padding">
              <Typography variant="h6" gutterBottom>
                Account Info
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='Account ID'
                      id='accountId'
                      name='accountId'
                      autoFocus
                      required
                      value={account.email}
                      error={error.key === 'email' ? true : false}
                      helperText={error.key === 'email' ? error.msg : 'Cannot Change Later'}
                      onChange={(e) => { setAccount({...account, email: e.target.value })}}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='Password'
                      id='password'
                      name='password'                      
                      required
                      value={account.password}
                      error={error.key === 'password' ? true : false}
                      onChange={(e) => { setAccount({...account, password: e.target.value })}}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                { props.user.role === 3 ?
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='Client'
                      id='client'
                      name='client'                      
                      required
                      value={account.client}
                      error={error.key === 'client' ? true : false}
                      onChange={(e) => { setAccount({...account, client: e.target.value })}}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                : null }
                {/*<Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='First Name'
                      id='firstName'
                      name='firstName'
                      autoFocus
                      required
                      value={account.firstName}
                      error={error.key === 'firstName' ? true : false}
                      helperText={error.key === 'firstName' ? error.msg : 'Cannot Change Later'}
                      onChange={(e) => { setAccount({...account, firstName: e.target.value })}}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='Last Name'
                      id='lastName'
                      name='lastName'
                      autoFocus
                      required
                      value={account.lastName}
                      error={error.key === 'lastName' ? true : false}
                      helperText={error.key === 'lastName' ? error.msg : null}
                      onChange={(e) => { setAccount({...account, lastName: e.target.value })}}
                      fullWidth
                    />
                  </FormControl>
                </Grid>*/}
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='Role'
                      id='role'
                      name='role'
                      select
                      required
                      value={account.role}
                      error={error.key === 'role' ? true : false}
                      helperText={error.key === 'role' ? error.msg : null}
                      onChange={(e) => { setAccount({...account, role: e.target.value, adminPortalAccess: e.target.value > 1 ? 1 : 0 })}}
                      fullWidth
                    >
                      <MenuItem key={"1"} value={'1'}>User</MenuItem>
                      {props.user.role === 3 ? <MenuItem key={'2'} value={'2'}>Admin</MenuItem> : null}
                      {props.user.role === 3 ? <MenuItem key={'3'} value={'3'}>Super Admin</MenuItem> : null}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='Admin portal access'
                      id='adminPortalAccess'
                      name='adminPortalAccess'
                      select
                      //required
                      value={account.adminPortalAccess}
                      error={error.key === 'adminPortalAccess' ? true : false}
                      helperText={error.key === 'adminPortalAccess' ? error.msg : null}
                      onChange={(e) => { setAccount({...account, adminPortalAccess: e.target.value })}}
                      fullWidth
                      disabled={account.role > 1}
                    >
                      <MenuItem key={"0"} value={'0'}>No</MenuItem>
                      <MenuItem key={'1'} value={'1'}>Yes</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='Alert email'
                      id='alertEmail'
                      name='alertEmail'                     
                      //required
                      value={account.alertEmail}
                      error={error.key === 'alertEmail' ? true : false}
                      helperText={error.key === 'alertEmail' ? error.msg : null}
                      onChange={(e) => { setAccount({...account, alertEmail: e.target.value })}}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom>
                    Reference Line Value
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="h6" gutterBottom>
                    Good
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="h6" gutterBottom>
                    Excellent
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                  {refproperty.map((ref, i) => {
                  return (
                    <FormControl required fullWidth>
                      <TextField
                        label={ref}
                        id={ref}
                        name={ref}                     
                        //required
                        value={account.gdRefLine[gdrefpropertyName[i]]}
                        error={error.key === gdrefpropertyName[i] ? true : false}
                        helperText={error.key === gdrefpropertyName[i] ? error.msg : null}
                        //onChange={(e) => { setAccount({...account, [refpropertyName[i]]: e.target.value })}}
                        onChange={(e) => { setAccount({
                          ...account,
                          gdRefLine:{
                            ...account.gdRefLine,
                              [gdrefpropertyName[i]]: e.target.value
                          }
                         })}}
                        fullWidth
                      />
                    </FormControl>
                  )})}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                  {refproperty.map((ref, i) => {
                  return (
                    <FormControl required fullWidth>
                      <TextField
                        label={ref}
                        id={ref}
                        name={ref}                     
                        //required
                        value={account.exRefLine[exrefpropertyName[i]]}
                        error={error.key === exrefpropertyName[i] ? true : false}
                        helperText={error.key === exrefpropertyName[i] ? error.msg : null}
                        //onChange={(e) => { setAccount({...account, [refpropertyName[i]]: e.target.value })}}
                        onChange={(e) => { setAccount({
                          ...account,
                          exRefLine:{
                            ...account.exRefLine,
                              [exrefpropertyName[i]]: e.target.value
                          }
                         })}}
                        fullWidth
                      />
                    </FormControl>
                  )})}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                  <Typography variant="h6" component="h0" justify="flex-start" gutterBottom>
                      Data Frequency:
                    </Typography>
                    <Typography variant="h6" component="h2" >
                    <label><input type="checkbox" id='1' value="1" checked={account.graphDisplayRange.includes(1)} onChange={hrsHandle} />1 hours &nbsp;&nbsp;</label>
                    <label><input type="checkbox" id='3' value="3" checked={account.graphDisplayRange.includes(3)} onChange={hrsHandle} />3 hours &nbsp;&nbsp;</label>
                    <label><input type="checkbox" id="8" value="8" checked={account.graphDisplayRange.includes(8)} onChange={hrsHandle} />8 hours &nbsp;&nbsp;</label>
                    <label><input type="checkbox" id="24" value="24" checked={account.graphDisplayRange.includes(24)} onChange={hrsHandle} />24 hours </label>
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <Typography variant="h6" component="h0" justify="flex-start" gutterBottom>
                      Device ID
                      <Typography variant="h6" component="h2" >
                        <label style={{fontSize:16}}><input type="checkbox" id='selectAll' value="selectAll" onChange={handleSelectAll} checked={isCheckAll}/>Select all &nbsp;&nbsp;</label>
                        {/*<label><input type="checkbox" id="deselectAll" value="deselectAll" checked={deviceId.length===0}/>Deselect all </label>*/}                       
                      </Typography>
                    </Typography>

                    <Typography variant="h6" component="h2" >                   
                    {listItems}
                    </Typography>
                    {/*<Checkbox id='1234' value='testing'>Testing</Checkbox> */}
                    {/*<FormControlLabel control={<Checkbox defaultChecked />} label="Label" />*/}
                  </FormControl>
                </Grid>


                {/* <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <InputLabel>Device</InputLabel>
                    <Select
                      label='Device'
                      id='device'
                      name='device'
                      select
                      required
                      multiple
                      value={account.device}
                      error={error.key === 'device' ? true : false}
                      helperText={error.key === 'device' ? error.msg : null}
                      onChange={(e) => { setAccount({...account, device: e.target.value })}}
                    >
                      <MenuItem key={"1"} value={'1'}>Guest</MenuItem>
                      <MenuItem key={'2'} value={'2'}>User</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSaveButtonHandler}
            >
              <SaveIcon />
                Save
              </Button>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  )
}


const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};


export default connect(mapStateToProps, null)(withDialog(withSnackbar(DeviceAddDrawer)));

