import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { TextField, Grid, Button, Paper, Typography, FormControl, List, ListItem, ListItemText } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';
import { IAQ_TYPE, EM_TYPE } from '../../utils/constants';
import toHKTimeString from '../../utils/to-hk-time-string';

import SubjectIcon from '@material-ui/icons/Subject';
import DescriptionIcon from '@material-ui/icons/Description';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import PageLoadingView from '../../components/PageLoadingView/PageLoadingView';
import PageErrorView from '../../components/PageErrorView/PageErrorView';

import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';
import SurveyTable, { idLabelDict, idFuncFunction } from "./components/SurveyTable";
import FileItem from "./components/FileItem";
import { convertToColumn, getXlsxAsBlob } from "../../lib/lib-exceljs";

const useInterval = (callback, delay, param) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [param]);
}

const SurveyPage = props => {
  const [loaded, setLoaded] = useState(false);

  const [error, setError] = useState(null);

  const current = new Date(new Date().toDateString());
  const maxDate = new Date(current.setDate(current.getDate() + 1));
  const minDate = new Date(current.setDate(current.getDate() - 6));
  const [exportFileClick, setExportFileClick] = useState(false);
  const [filePreparing, setFilePreparing] = useState(false);
  const [to, setTo] = useState(maxDate);
  const [from, setFrom] = useState(minDate);

  const [fileName, setFileName] = useState(null);
  const [tempUrl, setTempUrl] = useState(null);

  const [surveyInitLoaded, setSurveyInitLoaded] = useState(false);
  const [surveyData, setSurveyData] = useState([]);
  const [surveyLastTime, setSurveyLastTime] = useState(null);

  const dateDisabled = date => (false);

  useEffect(() => {
    fetchSurveyData();
  }, []);

  const exportButtonClick = async (e) => {
    setExportFileClick(true);
    setFilePreparing(true);

    await Promise.all([exportData()]);
    setFilePreparing(false);
  }

  const exportData = async () => {
    const filteredSurveyData = surveyData.filter(record => (record.timestamp * 1000) >= from.valueOf() && (record.timestamp * 1000) <= to.valueOf());

    if (filteredSurveyData.length > 0) {
      const headers = Object.keys(idLabelDict);
      const columns = headers.map(header => {
        if (header =="deviceId") {
          return convertToColumn(header, idLabelDict[header], 25);
        } else {
          return convertToColumn(header, idLabelDict[header]);
        }
      })
      const data = filteredSurveyData.map(record => {
        headers.forEach(key => {
          record[key] = idFuncFunction(key)(record[key]);
        })
      })
      const file = await getXlsxAsBlob(filteredSurveyData, headers, columns, "SURVEY");
      const tempUrl = window.URL.createObjectURL(file);
      setFileName("surveyData_" + moment(from).format("YYYYMMDD") + "_" + moment(to).format("YYYYMMDD")  + ".xlsx");
      setTempUrl(tempUrl);
    } else {
      setError("EMPTY");
    }
  }

  const fetchSurveyData = async () => {
    // first loaded get 24 hours
    let querys = surveyInitLoaded && surveyLastTime ? [`?timestamp=${surveyLastTime}`] : [];

    let surveyApiResult = await api('get', `${envars.telemetryServiceUrl}/survey?client=${props.user.client ?? ""}`);
    if (surveyApiResult.data.success) {
      setLoaded(true);
      const results = surveyApiResult.data.result;
      if (results && results.length > 0) {
        const lastDataTime = moment(results[results.length - 1].timestamp, 'YYYY-MM-DDTHH:mm:ss').add(1, 's');
        setSurveyLastTime(lastDataTime.valueOf()); // save the next time point;
      }

      let data;
      if (!surveyInitLoaded) {
        setSurveyInitLoaded(true);
        data = [...results];
      } else {
        data = [...surveyData];
        if (results && results.length > 0) {
          results.forEach(result => { data.push(result) });
        }
      }

      data.forEach(data => data["timestamp"] = data["createTime"]._seconds);
      // filter outdate data
      data.sort((a, b) => {
        return (b.timestamp - a.timestamp)
      });

      //   data.forEach(d => d.timestamp = toHKTimeString(d.timestamp));

      setSurveyData(data);

    } else {
      handleApiFailureWithDialog(props.requestDialog, surveyApiResult);
    }
  }

  if (!loaded) {
    return <PageLoadingView />;
  }

  return (
    <div className="paper-with-padding" style={{margin: 0, width: 'calc(100vw - 200px)',}}>
    <Grid container alignItems="flex-start" justify="flex-start" spacing={1} >
      <Grid item xs>
        <Typography variant="h4" component="h2" color="secondary">
          Survey Data
        </Typography>
      </Grid>
      <Grid item xs>
        <Button
          variant="contained"
          onClick={() => {
            props.history.push('/');
          }}
        >
          <ArrowBackIosIcon />Back
        </Button>
      </Grid>
    </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Paper className="paper-with-padding">
            <Grid container spacing={3} alignItems='center'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={4}>
                  <KeyboardDateTimePicker
                    id='start-from'
                    variant="inline"
                    ampm={false}
                    label="Start From"
                    value={from}
                    onChange={setFrom}
                    shouldDisableDate={dateDisabled}
                    format="yyyy/MM/dd HH:mm"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <KeyboardDateTimePicker
                    id='end-to'
                    variant="inline"
                    ampm={false}
                    label="End To"
                    value={to}
                    onChange={setTo}
                    shouldDisableDate={dateDisabled}
                    format="yyyy/MM/dd HH:mm"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container>
                    <Button
                      onClick={exportButtonClick}
                      color="secondary"
                      variant="contained"
                    >
                      <DescriptionIcon />
                      Export
                    </Button>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {exportFileClick ? (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Paper className="paper-with-padding">
              <Grid item xs>
                <Typography variant="body1" component="h2">
                  <SubjectIcon />
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                {filePreparing ? (
                  <Grid item xs={12}>
                    <PageLoadingView />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <List>
                      <Grid item xs={12}>
                        {FileItem(fileName, tempUrl)}
                      </Grid>
                    </List>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      ) : null}
      <Grid>
        <SurveyTable data={surveyData} loaded={surveyInitLoaded} client={props.user.client} />
      </Grid>
    </div>
  )

}


const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};


export default connect(mapStateToProps, null)(withDialog(withSnackbar(SurveyPage)));
