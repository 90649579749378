import React from 'react';
import moment from 'moment';
import { Card, Typography, CardContent, CardActions, Button, Grid } from '@material-ui/core';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';
import toHKTimeString from '../../utils/to-hk-time-string';

const AccountCard = props => {
  let { account, onClickHandler } = props;

  const displayRole = (role) => {
    switch (role) {
      case 1:
      case 11:
        return "user";
      case 2:
      case 12:
        return "admin";
      case 3:
        return "super admin";
    }
  }

  return (
    <Card key={`device-card-${account.email}`} >
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Email: {account.email}
        </Typography>
        <Typography color="textSecondary" component="h2">
          First Name: {account.firstName}
        </Typography>
        <Typography variant="h5" component="h4">
          Last Name: {account.lastName}
        </Typography>
        <Typography variant="body2" component="h2" noWrap={true}>
          Role: {displayRole(account.role)}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="h5">
          Update Time:
        </Typography>
        <Typography component="h5">
          {toHKTimeString(account.updateTime)}
        </Typography>
        <Grid container justify="flex-end">
          <Button size="small" variant="contained" color="secondary" onClick={onClickHandler}>Details<NextIcon /></Button>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AccountCard;