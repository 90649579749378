import React, { Component, useState, useEffect } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import SubjectIcon from '@material-ui/icons/Subject';
import moment from "moment";

import envars from '../../../envars';
import api, { handleApiFailureWithDialog } from '../../../utils/api';

import PageLoadingView from '../../../components/PageLoadingView/PageLoadingView';
import CustomizedTable from '../../../components/CustomizedTable/CustomizedTable';

const titleDict = {
    "temperature": "Temperature on board (°C)",
    "humidity": "Relative Humidity (%)",
    "co2": "CO2 (ppm)",
    "voc": "TVOC (ppb)",
    "pm10": "PM 10 (μg/m3)",
    "pm25": "PM 2.5 (μg/m3)",
    "AirTemperature": "Air Temperature (°C)",
    "AirVelocity": "Air Velocity (m/s)",
    "RadTemperature": "Radiant Temperature (°C)",
    "SoundLevel": "Sound Level (dB)",
    "light": "Light (lm)"
}

const idLabelDict = {
    "timedate": "Date",
    "attribute": "Attribute",
    "eventtype": "Trigger Type",
    "timestamp": "Timestamp",
    "deviceId": "Device ID",
    "location": "Location",
    "qualityAcceptance": "Q1",
    "thermalSensation": "Q2",
    "threeAural": "Q3 Aural",
    "threeVisual": "Q3 Visual",
    "threeOverall": "Q3 Overall",
    "fourAirQuality": "Q4 Air Quality",
    "fourThermal": "Q4 Thermal",
    "fourAural": "Q4 Aural",
    "fourVisual": "Q4 Visual",
    "fourOverall": "Q4 Overall",
    ...titleDict
}

const idFuncFunction = (key) => {
    switch (key) {
        default:
          return (value) => value;
    }
}

// const TABLE_COLUMNS = ([
//   {
//     minWidth: 30, id: 'timestamp', label: 'Timestamp',
//   },
//   {
//     minWidth: 30, id: 'hcn', label: 'HCN\u00A0(-)',
//     format: (value) => parseFloat(value.toFixed(4))
//   }
// ])

export {idLabelDict, idFuncFunction};
export default (props) => {
  const { client } = props;
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const fetchData = async () => {
    
    let apiResult = await api('get', `${envars.telemetryServiceUrl}/alertdata`);
    if (apiResult.data.success) {
        setLoaded(true);
      const results = apiResult.data.result;

      setData([...results]);
      setColumns(Object.keys(results[0]).map(key => { return { minWidth:30, id: key, label: idLabelDict[key], format: idFuncFunction(key)} }));
    } else {
      setLoaded(false);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  useEffect(() => {
    fetchData();
  }, []);

  if(!loaded){
    return <PageLoadingView />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        <Paper className="paper-with-padding">
          <Grid item>
            <Typography variant="h5" component="h2">
              <SubjectIcon /> Survey Data
            </Typography>
          </Grid>
          <Grid item>
            <CustomizedTable
              name={'calibrationTable'}
              rows={data}
              columns={columns}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
};
