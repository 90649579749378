let envars;
if (process.env.REACT_APP_EVQSENSE_PORTAL_ENV === 'prd') {
  envars = {
    authServiceUrl: "https://evqsense-auth-service-prd-pxc7sacouq-an.a.run.app",
    telemetryServiceUrl: "https://evqsense-telemetry-service-prd-pxc7sacouq-an.a.run.app",
    deviceServiceUrl: "https://evqsense-device-service-prd-pxc7sacouq-an.a.run.app",
    userServiceUrl: "https://evqsense-user-service-prd-pxc7sacouq-an.a.run.app",
    aiServiceUrl: "https://evqsense-ai-training-pxc7sacouq-an.a.run.app"
  }
} else if (process.env.REACT_APP_EVQSENSE_PORTAL_ENV === 'dev') {
  envars = {
    authServiceUrl: "https://evqsense-auth-service-dev-3togabxujq-an.a.run.app",
    telemetryServiceUrl: "https://evqsense-telemetry-service-dev-3togabxujq-an.a.run.app",
    deviceServiceUrl: "https://evqsense-device-service-dev-3togabxujq-an.a.run.app",
    userServiceUrl: "https://evqsense-user-service-prd-pxc7sacouq-an.a.run.app",
    aiServiceUrl: "https://evqsense-ai-training-pxc7sacouq-an.a.run.app"
  }
} else {
  envars = {
    authServiceUrl: "https://evqsense-auth-service-prd-pxc7sacouq-an.a.run.app",
    // authServiceUrl: "http://localhost:8085",
     telemetryServiceUrl: "https://evqsense-telemetry-service-prd-pxc7sacouq-an.a.run.app",
    // telemetryServiceUrl: "http://localhost:5081",
    deviceServiceUrl: "https://evqsense-device-service-prd-pxc7sacouq-an.a.run.app",
    // deviceServiceUrl: "http://localhost:8081",
    userServiceUrl: "https://evqsense-user-service-prd-pxc7sacouq-an.a.run.app",
    // userServiceUrl: "http://localhost:8082",
    aiServiceUrl: "https://evqsense-ai-training-pxc7sacouq-an.a.run.app",
    // aiServiceUrl: "http://localhost:5000",
  }
}

if (process.env.REACT_APP_TELEMETRY_ENDPOINT) {
  envars["telemetryServiceUrl"] = process.env.REACT_APP_TELEMETRY_ENDPOINT;
}

export default envars;