import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { TextField, Grid, Button, Paper, Typography, FormControl, List, ListItem, ListItemText } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';

import SubjectIcon from '@material-ui/icons/Subject';
import DescriptionIcon from '@material-ui/icons/Description';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import PageLoadingView from '../../components/PageLoadingView/PageLoadingView';

import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';

import SuperAdminAlertTable  from './components/SuperAdminAlertTable';

const useInterval = (callback, delay, param) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [param]);
}

const SuperAdminAlertPage = props => {
  const [error, setError] = useState(null);

  const maxDate = new Date(new Date().toDateString());
  const current = new Date(new Date().toDateString());
  const minDate = new Date(current.setDate(current.getDate() - 7));  
  const [exportFileClick, setExportFileClick] = useState(false);
  const [filePreparing, setFilePreparing] = useState(false);
  const [to, setTo] = useState(maxDate);
  const [from, setFrom] = useState(minDate);

    const [fileName, setFileName] = useState(null);
    const [tempUrl, setTempUrl] = useState(null);

  const [surveyInitLoaded, setSurveyInitLoaded] = useState(false);
  const [surveyData, setSurveyData] = useState([]);
  const [surveyLastTime, setSurveyLastTime] = useState(null);

  const dateDisabled = date => (false);

  // useEffect(() => {
  //   fetchSurveyData();
  // }, []);

  // const exportButtonClick = async (e) => {
  //   setExportFileClick(true);
  //   setFilePreparing(true);

  //   await Promise.all([exportData()]);
  //   setFilePreparing(false);
  // }


  return (
    <div className="paper-with-padding">
      <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
        <Grid item >
          <Typography variant="h4" component="h2" color="secondary">
            Adnormal Data
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => {
              props.history.push('/');
            }}
          >
            <ArrowBackIosIcon />Back
          </Button>
        </Grid>
      </Grid>
      <SuperAdminAlertTable />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};


export default connect(mapStateToProps, null)(withDialog(withSnackbar(SuperAdminAlertPage)));
