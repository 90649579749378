import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import './App.css';
import withGlobalUI from './utils/with-global-ui';
import api from './utils/api';
import envars from './envars';

import SnackbarManager from './containers/SnackbarManager/SnackbarManager';
import DialogManager from './containers/DialogManager/DialogManager';
import TopBar from './containers/TopBar/TopBar';
import NavBar from './containers/NavBar/NavBar';
import PageLoadingView from './components/PageLoadingView/PageLoadingView';

import LandingPage from './pages/LandingPage/LandingPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import DashboardPageV2 from './pages/DashboardPage/DashboardPageV2';
import DevicePage from './pages/DevicePage/DevicePage';
import ExportDataPage from './pages/ExportDataPage/ExportDataPage';
import AccountPage from './pages/AccountPage/AccountPage';
import AccountDetailPage from './pages/AccountDetailPage/AccountDetailPage';
import SuperAdminAlertPage from './pages/SuperAdminAlertPage/SuperAdminAlertPage';
import SuperAdminChartPage from './pages/SuperAdminChartPage/SuperAdminChartPage';

import IPDomainPage from './pages/IPDomainPage/IPDomainPage';

import SurveyPage from './pages/SurveyPage/SurveyPage';

import TestPage from './pages/TestPage/TestPage';

const routeDict = {
    "saalert": SuperAdminAlertPage
    ,"export": ExportDataPage
    ,"accounts": AccountPage
    ,"survey": SurveyPage
    ,"sachart": SuperAdminChartPage
    ,"dashboardV2" : DashboardPageV2
}

class App extends Component {
  state = {
    width: 0,
    height: 0,
    initAppDone: false,
    mobileShowMenu: false,
  };

  componentDidMount = () => {
    let authToken = sessionStorage.getItem('authToken');
    if (authToken) {
      // console.log('resume login');
      this.props.login(authToken);
    }
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.props.initApp();
    this.setState({ initAppDone: true });
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  // current available role: 1, 2, 3, 11, 12
  getUserAppropriateFunction() {
    const route = [];
    if (this.props.user.role !== 21) {
      route.push(<Route path="/" component={DashboardPage} exact />);
    } else {
      route.push(<Route path="/" component={DashboardPageV2} exact />)
    }
    route.push(<Route path="/devices/:id" component={DevicePage} />);
    route.push(<Route path="/account/:email" component={AccountDetailPage} />);
    
    this.props.user.aPortalFunc.forEach(key => {
      route.push(<Route path={`/${key}`} component={routeDict[key]} />);
    })
    
    return <Switch>
      {route}
    </Switch>
  }

  render() {

    if (!this.props.user) {
      return (
        <div style={{ width: this.state.width, height: this.state.height}} >
          <BrowserRouter>
            <Switch>
              <Route path="/" component={LandingPage} />
            </Switch>
          </BrowserRouter>
          <SnackbarManager />
          <DialogManager />
        </div>
      );
    }

    if (!this.state.initAppDone) {
      return (
        <div style={{ width: this.state.width, height: this.state.height }}>
          <CssBaseline />
          <PageLoadingView />
          <SnackbarManager />
          <DialogManager />
        </div>
      );
    }

    return (
      <BrowserRouter>
        <div className="App">
          <CssBaseline />
          <TopBar onOpenMobileMenu={() => this.setState({ mobileShowMenu: true })} />
          <NavBar user={this.props.user} mobileOpen={this.state.mobileShowMenu} onMobileClose={() => this.setState({ mobileShowMenu: false })} />
          <main className="page-container">
              {this.getUserAppropriateFunction()}
          </main>
          <SnackbarManager />
          <DialogManager />
        </div>
      </BrowserRouter>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    user: state.system.user,
    snackbarManager: state.system.snackbarManager,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (authToken) =>
      dispatch({
        type: 'LOGIN',
        payload: {
          authToken,
        },
      }),
    initApp: () =>
      dispatch({
        type: 'INIT_APP',
        payload: {},
      })
  };
};

export default withGlobalUI(connect(mapStateToProps, mapDispatchToProps)(App));
