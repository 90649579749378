import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { TextField, Grid, Button, Paper, Typography, FormControl, List, ListItem, ListItemText, Select, MenuItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';
import { IAQ_TYPE, EM_TYPE } from '../../utils/constants';
import toHKTimeString from '../../utils/to-hk-time-string';

import PageLoadingView from '../../components/PageLoadingView/PageLoadingView';
import PageErrorView from '../../components/PageErrorView/PageErrorView';

import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';
import DeviceTelemetryTable from '../../containers/DeviceTelemetryTable/DeviceTelemetryTable';
import DeviceTelemetryFrequencyTable from '../../containers/DeviceTelemetryFrequencyTable/DeviceTelemetryFrequencyTable';
import DeviceConfigDrawer from '../../containers/DeviceConfigDrawer/DeviceConfigDrawer';
import DeviceLocationEditDialog from '../../containers/DeviceLocationEditDialog/DeviceLocationEditDialog';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush, ReferenceArea, ReferenceLine, Label } from "recharts";
//import CustomLineChart from '../../components/Chart/CustomLineChart';

const useInterval = (callback, delay, param) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [param]);
}

const DashboardPageV2 = props => {
  const deviceId = props.user.devices[0];
  const [loaded, setLoaded] = useState(false);

  const [error, setError] = useState(null);
  const [editingConfig, setEditingConfig] = useState(false);
  const [editingLocation, setEditingLoaction] = useState(false);

  const [device, setDevice] = useState(null);

  const [telemetryInitLoaded, setTelemetryInitLoaded] = useState(false);
  const [telemetryData, setTelemetryData] = useState([]);
  const [telemetryLastTime, setTelemetryLastTime] = useState(null);

  let timeInterval = 5000;

  const [graphTelemetryData, setGraphTelemetryData] = useState([]);

  const [displayOption, setDisplayOption] = useState(props.user.graphDisplayRange
    ? props.user.graphDisplayRange.length > 0
      ? props.user.graphDisplayRange
      : [1, 3, 8, 24]
    : [1, 3, 8, 24]);
  const [displayDuration, setDisplayDuration] = useState(displayOption.at(-1));

  const [pm10GdRef, setPm10GdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.pm10GdRef : null);
  const [pm25GdRef, setPm25GdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.pm25GdRef : null);
  const [coGdRef, setCoGdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.coGdRef : null);
  const [co2GdRef, setCo2GdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.co2GdRef : null);
  const [vocGdRef, setVocGdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.vocGdRef : null);
  const [ozoGdRef, setOzoGdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.ozoGdRef : null);
  const [pm10ExRef, setPm10ExRef] = useState(props.user.exRefLine ? props.user.exRefLine.pm10ExRef : null);
  const [pm25ExRef, setPm25ExRef] = useState(props.user.exRefLine ? props.user.exRefLine.pm25ExRef : null);
  const [coExRef, setCoExRef] = useState(props.user.exRefLine ? props.user.exRefLine.coExRef : null);
  const [co2ExRef, setCo2ExRef] = useState(props.user.exRefLine ? props.user.exRefLine.co2ExRef : null);
  const [vocExRef, setVocExRef] = useState(props.user.exRefLine ? props.user.exRefLine.vocExRef : null);
  const [ozoExRef, setOzoExRef] = useState(props.user.exRefLine ? props.user.exRefLine.ozoExRef : null);
  const [grading, setGrading] = useState({
    "temperature": "A1",
    "humidity": "A1",
    "co2": "A1",
    "pm10": "A1",
    "pm25": "A1",
    "voc": "A1"
  })
  const dataProps = ["temperature", "humidity", "pm10", "pm25", "co", "co2", "voc"/*, "hcn", "AirVelocity", "AirTemperature", "SoundLevel", "light", "RadTemperature", "ozo"*/];
  const dataPropsDisplayName = {
    "temperature": "Temperature"
    , "humidity": "Humidity"
    , "pm10": "PM 10"
    , "pm25": "PM 2.5"
    , "co": "CO"
    , "co2": "CO2"
    , "voc": "TVOC"
    , "hcn": "HCN"
    // , "AirVelocity": "Air Velocity"
    // , "AirTemperature": "Air Temperature"
    // , "SoundLevel": "SoundLevel"
    // , "light": "light"
    // , "RadTemperature": "RadTemperature"
    // , "ozo": "O3"
  };
  const dataPropsUnit = {
    "temperature": "(°C)"
    , "humidity": "(%)"
    , "pm10": "(μg/m3)"
    , "pm25": "(μg/m3)"
    , "co": "(ppm)"
    , "co2": "(ppm)"
    , "voc": "(ppb)"
    , "hcn": "(μg/m3)"
    // , "AirVelocity": "(m/s)"
    // , "AirTemperature": "(°C)"
    // , "SoundLevel": "(dB)"
    // , "light": "(lm)"
    // , "RadTemperature": "(°C)"
    // , "ozo": "(ppm)"
  };
  const gdrefpropertyName = ["pm10GdRef", "pm25GdRef", "coGdRef", "co2GdRef", "vocGdRef", "ozoGdRef"];
  const color = ["#8884d8", "#82ca9d", "#82ca00", "#5584d8", "#1084d8", "#3084d8", "#55ca00", "#c7c227"];
  const gdrefLineVal = [pm10GdRef, pm25GdRef, coGdRef, co2GdRef, vocGdRef, ozoGdRef];
  const gdrefFunction = [setPm10GdRef, setPm25GdRef, setCoGdRef, setCo2GdRef, setVocGdRef, setOzoGdRef];
  const exrefpropertyName = ["pm10ExRef", "pm25ExRef", "coExRef", "co2ExRef", "vocExRef", "ozoExRef"];
  const exrefLineVal = [pm10ExRef, pm25ExRef, coExRef, co2ExRef, vocExRef, ozoExRef];
  const exrefFunction = [setPm10ExRef, setPm25ExRef, setCoExRef, setCo2ExRef, setVocExRef, setOzoExRef];
  const refIndex = ["pm10", "pm25", "co", "co2", "voc", "ozo"];

  useEffect(() => {
    if (props.user.role !== 3 && !props.user.devices.includes(deviceId)) {
      return props.requestDialog({
        title: 'Session expired',
        text: 'Please login again',
        buttons: [{
          text: 'ok',
          onClick: () => {
            sessionStorage.clear();
            window.location.reload();
            return;
          }
        }],
      });
    }
    fetchDeviceData();
    fetchTelemetryData();
  }, []);

  useInterval(async () => {
    await fetchTelemetryData();
  }, timeInterval, telemetryLastTime);

  const fetchDeviceData = async () => {
    let deviceApiResult = await api('get', `${envars.deviceServiceUrl}/device/${deviceId}`, null);
    if (deviceApiResult.data.success) {
      let device = deviceApiResult.data.result;
      setDevice(device);
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, deviceApiResult);
    }
  }
  const fetchTelemetryData = async () => {
    // first loaded get 24 hours
    let querys = telemetryInitLoaded && telemetryLastTime ? [`?timestamp=${telemetryLastTime}`] : [];
    // console.log("query time", telemetryLastTime, moment(telemetryLastTime).format());

    let telemetryApiResult = await api('get', `${envars.telemetryServiceUrl}/telemetry/${deviceId}/data${querys.join('&')}`);
    if (telemetryApiResult.data.success) {
      const results = telemetryApiResult.data.result;
      if (results && results.length > 0) {
        const lastDataTime = moment(results[results.length - 1].timestamp, 'YYYY-MM-DDTHH:mm:ss').add(60, 's');
        setTelemetryLastTime(lastDataTime.valueOf()); // save the next time point;
      }

      let data;
      if (!telemetryInitLoaded) {
        setTelemetryInitLoaded(true);
        data = [...results];
      } else {
        //data = [...telemetryData];
        data = [];
        if (results && results.length > 0) {
          results.forEach(result => { data.push(result) });
        }
      }


      // filter outdate data
      data = data.filter(d => moment().diff(moment(d.timestamp, 'YYYY-MM-DDTHH:mm:ss'), 'h') <= 4);
      data.sort((a, b) => {
        return (moment(b.timestamp, 'YYYY-MM-DDTHH:mm:ss').valueOf() - moment(a.timestamp, 'YYYY-MM-DDTHH:mm:ss').valueOf())
      });

      dataProps.map(prop => {
        data.forEach(d => d[prop] ? d[prop] = d[prop].toFixed(2) : "");
      })

      data.forEach(d => d.timestamp = toHKTimeString(d.timestamp));

      setTelemetryData(data);
      setGraphTelemetryData(data);
      setLoaded(true);

    } else {
      console.log(`Response: ${telemetryApiResult.status}, ${telemetryApiResult.statusText}`);
      // handleApiFailureWithDialog(props.requestDialog, telemetryApiResult);
    }
  }


  // useEffect(async () =>{
  // }, [])
  useInterval(async () => {
    fetchGradingData();
  }, timeInterval * 10, telemetryLastTime);

  const fetchGradingData = async () => {

    let telemetryApiResult = await api('get', `${envars.telemetryServiceUrl}/telemetry/${deviceId}/grading`);
    
    if (telemetryApiResult.data.success) {
      const results = telemetryApiResult.data.result;
      if (results) {
        setGrading(results)
      }
    }
    //   let data;
    //   if (!telemetryInitLoaded) {
    //     setTelemetryInitLoaded(true);
    //     data = [...results];
    //   } else {
    //     //data = [...telemetryData];
    //     data = [];
    //     if (results && results.length > 0) {
    //       results.forEach(result => { data.push(result) });
    //     }
    //   }


    //   // filter outdate data
    //   data = data.filter(d => moment().diff(moment(d.timestamp, 'YYYY-MM-DDTHH:mm:ss'), 'h') <= 4);
    //   data.sort((a, b) => {
    //     return (moment(b.timestamp, 'YYYY-MM-DDTHH:mm:ss').valueOf() - moment(a.timestamp, 'YYYY-MM-DDTHH:mm:ss').valueOf())
    //   });

    //   dataProps.map(prop => {
    //     data.forEach(d => d[prop] ? d[prop] = d[prop].toFixed(2) : "");
    //   })

    //   data.forEach(d => d.timestamp = toHKTimeString(d.timestamp));

    //   setTelemetryData(data);
    //   setGraphTelemetryData(data);
    //   setLoaded(true);

    // } else {
    //   console.log(`Response: ${telemetryApiResult.status}, ${telemetryApiResult.statusText}`);
    //   // handleApiFailureWithDialog(props.requestDialog, telemetryApiResult);
    // }
  }
  


  if (!loaded) {
    return <PageLoadingView />;
  }
  return (
    <div className="paper-with-padding">
      <Grid item xs={12} sm={12}>
        <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
          <Grid item >
            <Typography variant="h4" component="h2" color="secondary">
              Dashboard
            </Typography>
          </Grid>
        </Grid>
        <Paper className="paper-with-padding">
          <Grid container spacing={2}>
            <Grid item xs={2} spacing={6}>
              {
                telemetryData[telemetryData.length - 1]
                  ? dataProps
                    .filter(key => !device.dataColumns || device.dataColumns.length === 0 || device.dataColumns.includes(key))
                    .map((data, i) => {
                      return (
                        <Grid container>
                          <Grid item xs={7}>{dataPropsDisplayName[data]}: </Grid>
                          <Grid item xs={4}>{telemetryData[telemetryData.length - 1] ? telemetryData[0][data] : "NA"}</Grid>
                          <Grid item xs={1}>{dataPropsUnit[data]}</Grid>
                        </Grid>)

                    })
                  : <></>
              }
            </Grid>
            <Grid style={{ display: "flex", flexWrap: "wrap" }} item xs={10}>
              {dataProps
                .filter(key => !device.dataColumns || device.dataColumns.length === 0 || device.dataColumns.includes(key))
                .map((data, i) => {
                  if (!(telemetryData.every(obj => obj[data] === null))) {
                    const dataMax = Math.max(...telemetryData.map(obj => parseFloat(obj[data])));
                    return (
                      <ResponsiveContainer width='49%' height={225}>
                        <LineChart syncId={'sync'} cx={150} cy={150} innerRadius={20} outerRadius={140}
                          data={[...graphTelemetryData].sort((c, d) => c.timestamp.localeCompare(d.timestamp)).filter(d => moment().diff(moment(d.timestamp, 'YYYY-MM-DDTHH:mm:ss'), 'h') < displayDuration)}
                          margin={{ top: 20, bottom: 5, left: 20 }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="timestamp" tickFormatter={(unixTime) => moment(unixTime).format('HH:mm')} />
                          <YAxis domain={[0, dataMax]} >
                            <Label angle={-90} value={'Value ' + dataPropsUnit[data]} position='insideLeft' style={{ textAnchor: 'middle' }} />
                          </YAxis>
                          <Tooltip />
                          <Legend />
                          <Line type="linear" dataKey={data} stroke={color[i]} dot={false} name={dataPropsDisplayName[data]} />
                          <ReferenceLine y={gdrefLineVal[refIndex.indexOf(data)]} stroke="red" label={{ position: 'insideBottomRight', value: "Good" }}> <Label value={gdrefLineVal[refIndex.indexOf(data)]} position="insideBottomLeft" /></ReferenceLine>
                          <ReferenceLine y={exrefLineVal[refIndex.indexOf(data)]} stroke="red" label={{ position: 'insideBottomRight', value: "Excellent" }}> <Label value={exrefLineVal[refIndex.indexOf(data)]} position="insideBottomLeft" /></ReferenceLine>
                        </LineChart>
                      </ResponsiveContainer>
                    )
                  }
                })}
            </Grid>
          </Grid>
        </Paper>
        <Paper className="paper-with-padding">
          <Grid container spacing={2}>
            <Grid item xs={12} spacing={6}>
              { 
              telemetryData[telemetryData.length - 1] 
              ? 
              <div>
                <div>Grading</div>
                <div>
                  Temperature: {grading["temperature"]},
                  Humidity: {grading["humidity"]},
                  PM10: {grading["pm10"]},
                  PM25: {grading["pm25"]},
                  CO2: {grading["co2"]},
                  TVOC: {grading["voc"]},
                </div>
              </div>
              : <></>
              }
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  )

}


const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};


export default connect(mapStateToProps, null)(withDialog(withSnackbar(DashboardPageV2)));
