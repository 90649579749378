import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { TextField, Grid, Button, Paper, Typography, FormControl, List, ListItem, ListItemText, Select, MenuItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';
import { IAQ_TYPE, EM_TYPE } from '../../utils/constants';
import toHKTimeString from '../../utils/to-hk-time-string';

import PageLoadingView from '../../components/PageLoadingView/PageLoadingView';
import PageErrorView from '../../components/PageErrorView/PageErrorView';

import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';
import DeviceTelemetryTable from '../../containers/DeviceTelemetryTable/DeviceTelemetryTable';
import DeviceTelemetryFrequencyTable from '../../containers/DeviceTelemetryFrequencyTable/DeviceTelemetryFrequencyTable';
import DeviceConfigDrawer from '../../containers/DeviceConfigDrawer/DeviceConfigDrawer';
import DeviceLocationEditDialog from '../../containers/DeviceLocationEditDialog/DeviceLocationEditDialog';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush, ReferenceArea, ReferenceLine, Label } from "recharts";
//import CustomLineChart from '../../components/Chart/CustomLineChart';

const useInterval = (callback, delay, param) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [param]);
}

const DevicePage = props => {
  const deviceId = props.match.params.id;
  const [loaded, setLoaded] = useState(false);

  const [error, setError] = useState(null);
  const [editingConfig, setEditingConfig] = useState(false);
  const [editingLocation, setEditingLoaction] = useState(false);

  const [device, setDevice] = useState(null);

  const [telemetryInitLoaded, setTelemetryInitLoaded] = useState(false);
  const [telemetryData, setTelemetryData] = useState([]);
  const [telemetryLastTime, setTelemetryLastTime] = useState(null);
  const [snapData, setSnapData] = useState({});

  let timeInterval = 10000;

  const [graphTelemetryData, setGraphTelemetryData] = useState([]);
  
  const [displayOption, setDisplayOption] = useState(props.user.graphDisplayRange 
                                 ? props.user.graphDisplayRange.length > 0 
                                    ? props.user.graphDisplayRange 
                                    : [1,3,8,24] 
                                 : [1,3,8,24]);
  const [displayDuration, setDisplayDuration] = useState(displayOption.at(-1));

  const [pm10GdRef, setPm10GdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.pm10GdRef : null);
  const [pm25GdRef, setPm25GdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.pm25GdRef : null);
  const [coGdRef, setCoGdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.coGdRef : null);
  const [co2GdRef, setCo2GdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.co2GdRef : null);
  const [vocGdRef, setVocGdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.vocGdRef : null);
  const [ozoGdRef, setOzoGdRef] = useState(props.user.gdRefLine ? props.user.gdRefLine.ozoGdRef : null);
  const [pm10ExRef, setPm10ExRef] = useState(props.user.exRefLine ? props.user.exRefLine.pm10ExRef : null);
  const [pm25ExRef, setPm25ExRef] = useState(props.user.exRefLine ? props.user.exRefLine.pm25ExRef : null);
  const [coExRef, setCoExRef] = useState(props.user.exRefLine ? props.user.exRefLine.coExRef : null);
  const [co2ExRef, setCo2ExRef] = useState(props.user.exRefLine ? props.user.exRefLine.co2ExRef : null);
  const [vocExRef, setVocExRef] = useState(props.user.exRefLine ? props.user.exRefLine.vocExRef : null);
  const [ozoExRef, setOzoExRef] = useState(props.user.exRefLine ? props.user.exRefLine.ozoExRef : null);
  const dataProps = ["temperature", "humidity", "pm10", "pm25", "co", "co2", "voc", "hcn", "AirVelocity", "AirTemperature", "SoundLevel", "light", "RadTemperature", "ozo"];
  const dataPropsDisplayName = {
    "temperature":"Temperature"
    ,"humidity":"Humidity"
    ,"pm10":"PM 10"
    ,"pm25":"PM 2.5"
    ,"co":"CO"
    ,"co2":"CO2"
    ,"voc":"TVOC"
    ,"hcn":"HCN"
    ,"AirVelocity":"Air Velocity"
    ,"AirTemperature":"Air Temperature"
    ,"SoundLevel":"Sound Level"
    ,"light":"Light"
    ,"RadTemperature":"Radiant Temperature"
    ,"ozo":"O3"
  };
  const dataPropsSHKDisplayName = {
    "temperature":"Temperature"
    ,"humidity":"Humidity"
    ,"pm10":"PM 10"
    ,"pm25":"PM 2.5"
    ,"co":"CO"
    ,"co2":"CO2"
    ,"voc":"TVOC"
    ,"hcn":"HCN"
    ,"AirVelocity":"Air Velocity"
    ,"AirTemperature":"Air Temperature"
    ,"SoundLevel":"Sound Level"
    ,"light":"Light"
    ,"RadTemperature":"Radiant Temperature"
    ,"ozo":"O3"
  };
  const dataPropsUnit = {
    "temperature":"(°C)"
    ,"humidity":"(%)"
    ,"pm10":"(μg/m3)"
    ,"pm25":"(μg/m3)"
    ,"co":"(ppm)"
    ,"co2":"(ppm)"
    ,"voc":"(ppb)"
    ,"hcn":"(μg/m3)"
    ,"AirVelocity":"(m/s)"
    ,"AirTemperature":"(°C)"
    ,"SoundLevel":"(dB)"
    ,"light":"(lm)"
    ,"RadTemperature":"(°C)"
    ,"ozo":"(ppm)"
  };
  const gdrefpropertyName = ["pm10GdRef", "pm25GdRef", "coGdRef", "co2GdRef", "vocGdRef", "ozoGdRef"];
  const color = ["#8884d8", "#82ca9d", "#82ca00", "#5584d8", "#1084d8", "#3084d8", "#55ca00", "#c7c227"];
  const gdrefLineVal = [pm10GdRef, pm25GdRef, coGdRef, co2GdRef, vocGdRef, ozoGdRef];
  const gdrefFunction = [setPm10GdRef, setPm25GdRef, setCoGdRef, setCo2GdRef, setVocGdRef, setOzoGdRef];
  const exrefpropertyName = ["pm10ExRef", "pm25ExRef", "coExRef", "co2ExRef", "vocExRef", "ozoExRef"];
  const exrefLineVal = [pm10ExRef, pm25ExRef, coExRef, co2ExRef, vocExRef, ozoExRef];
  const exrefFunction = [setPm10ExRef, setPm25ExRef, setCoExRef, setCo2ExRef, setVocExRef, setOzoExRef];
  const refIndex = ["pm10", "pm25", "co", "co2", "voc", "ozo"];

  const setRef = async(device) =>{
    if(device.gdRefLine !== undefined){
      gdrefLineVal.map((val, i) => {
        if(!(device.gdRefLine[gdrefpropertyName[i]] === undefined || device.gdRefLine[gdrefpropertyName[i]] === null || device.gdRefLine[gdrefpropertyName[i]] === '') && device.gdRefLine[gdrefpropertyName[i]] != 0){
          gdrefFunction[i](device.gdRefLine[gdrefpropertyName[i]]);
          gdrefLineVal[i] = device.gdRefLine[gdrefpropertyName[i]];
        }
      })
    }
    if(device.exRefLine !== undefined){
      exrefLineVal.map((val, i) => {
        if(!(device.exRefLine[exrefpropertyName[i]] === undefined || device.exRefLine[exrefpropertyName[i]] === null || device.exRefLine[exrefpropertyName[i]] === '') && device.exRefLine[exrefpropertyName[i]] != 0){
          exrefFunction[i](device.exRefLine[exrefpropertyName[i]]);
          exrefLineVal[i] = device.exRefLine[exrefpropertyName[i]];
        }
      })
    }
    refIndex.map((val, i) => {
      if (gdrefLineVal[i] === exrefLineVal[i]){
        gdrefFunction[i](null);
      }
    })
  };

  useEffect(() => {
    if(props.user.role !== 3 && !props.user.devices.includes(deviceId)){
      return props.requestDialog({
        title: 'Session expired',
        text: 'Please login again',
        buttons: [{
          text: 'ok',
          onClick: () => {
            sessionStorage.clear();
            window.location.reload();
            return;
          }
        }],
      });
    }
    fetchAccountData();
    fetchDeviceData();
    fetchTelemetryData();
  }, []);

  useInterval(async () => {
    await fetchTelemetryData();
  }, timeInterval, telemetryLastTime);

  useInterval(async () => {
    await fetchTelemetryDataSnap();
  }, timeInterval, null);

  const fetchAccountData = async () => {
    let email = props.user.email;
    let accountApiResult = await api('get', `${envars.userServiceUrl}/account/${email}`, null);
    if (accountApiResult.data.success) {
      let account = accountApiResult.data.result;
      if (account.gdRefLine){
        gdrefLineVal.map((val, i) => {
          if (account.gdRefLine[gdrefpropertyName[i]] !== undefined || account.gdRefLine[gdrefpropertyName[i]] !== null){
            gdrefFunction[i](account.gdRefLine[gdrefpropertyName[i]]);
            gdrefLineVal[i] = account.gdRefLine[gdrefpropertyName[i]];
          }
        })
      }
      if (account.exRefLine){
        exrefLineVal.map((val, i) => {
          if (account.exRefLine[exrefpropertyName[i]] !== undefined || account.exRefLine[exrefpropertyName[i]] !== null){
            exrefFunction[i](account.exRefLine[exrefpropertyName[i]]);
            exrefLineVal[i] = account.exRefLine[exrefpropertyName[i]];
          }          
        })
      }
      if (account.graphDisplayRange && account.graphDisplayRange.length > 0){
        setDisplayOption(account.graphDisplayRange);
      }
      else{
        setDisplayOption([1,3,8,24]);
      }
      setDisplayDuration(displayOption.at(-1));
    } else {
      handleApiFailureWithDialog(props.requestDialog, accountApiResult);
    }
  }

  const fetchDeviceData = async () => {
    setLoaded(false);
    let deviceApiResult = await api('get', `${envars.deviceServiceUrl}/device/${deviceId}`, null);
    if (deviceApiResult.data.success) {
      let device = deviceApiResult.data.result;
      setDevice(device);
      setRef(device);
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, deviceApiResult);
    }
  }

  const fetchTelemetryData = async () => {
    // first loaded get 24 hours
    let querys = telemetryInitLoaded && telemetryLastTime ? [`?timestamp=${telemetryLastTime}`] : [];
    // console.log("query time", telemetryLastTime, moment(telemetryLastTime).format());

    let telemetryApiResult = await api('get', `${envars.telemetryServiceUrl}/telemetry/${deviceId}/data${querys.join('&')}`);
    if (telemetryApiResult.data.success) {
      const results = telemetryApiResult.data.result;
      if (results && results.length > 0) {
        const lastDataTime = moment(results[results.length-1].timestamp, 'YYYY-MM-DDTHH:mm:ss').add(60, 's');
        setTelemetryLastTime(lastDataTime.valueOf()); // save the next time point;
      }

      let data;
      if(!telemetryInitLoaded){
        setTelemetryInitLoaded(true);
        data = [...results];
      }else{
        //data = [...telemetryData];
        data = [];
        if (results && results.length > 0) {
          results.forEach(result => { data.push(result) });
        }
      }


      // filter outdate data
      data = data.filter(d => moment().diff(moment(d.timestamp, 'YYYY-MM-DDTHH:mm:ss'), 'h') <= 24);
      data.sort((a, b) => {
        return (moment(b.timestamp, 'YYYY-MM-DDTHH:mm:ss').valueOf() - moment(a.timestamp, 'YYYY-MM-DDTHH:mm:ss').valueOf())
      });

      dataProps.map(prop => {
        data.forEach(d => d[prop] ? d[prop] = d[prop].toFixed(2) : "");
      })

      data.forEach(d => d.timestamp = toHKTimeString(d.timestamp));

      setTelemetryData(data);
      setGraphTelemetryData(data);

      // console.log("api result", telemetryApiResult.data.result);
      // console.log("concat data", data.length);

    } else {
      handleApiFailureWithDialog(props.requestDialog, telemetryApiResult);
    }
  }

  const fetchTelemetryDataSnap = async () => {
    // first loaded get 24 hours
    // let querys = telemetryInitLoaded && telemetryLastTime ? [`?timestamp=${telemetryLastTime}`] : [];
    // console.log("query time", telemetryLastTime, moment(telemetryLastTime).format());

    let telemetryApiResult = await api('get', `${envars.telemetryServiceUrl}/telemetry/${deviceId}/datasnap`);
    if (telemetryApiResult.data.success) {
      const results = telemetryApiResult.data.result;
      console.log(results);
      setSnapData(results[results.length-1]);
      // if (results && results.length > 0) {
      //   const lastDataTime = moment(results[results.length-1].timestamp, 'YYYY-MM-DDTHH:mm:ss').add(60, 's');
      //   setTelemetryLastTime(lastDataTime.valueOf()); // save the next time point;
      // }

      // let data;
      // if(!telemetryInitLoaded){
      //   setTelemetryInitLoaded(true);
      //   data = [...results];
      // }else{
      //   //data = [...telemetryData];
      //   data = [];
      //   if (results && results.length > 0) {
      //     results.forEach(result => { data.push(result) });
      //   }
      // }


      // // filter outdate data
      // data = data.filter(d => moment().diff(moment(d.timestamp, 'YYYY-MM-DDTHH:mm:ss'), 'h') <= 24);
      // data.sort((a, b) => {
      //   return (moment(b.timestamp, 'YYYY-MM-DDTHH:mm:ss').valueOf() - moment(a.timestamp, 'YYYY-MM-DDTHH:mm:ss').valueOf())
      // });

      // dataProps.map(prop => {
      //   data.forEach(d => d[prop] ? d[prop] = d[prop].toFixed(2) : "");
      // })

      // data.forEach(d => d.timestamp = toHKTimeString(d.timestamp));

      // setTelemetryData(data);
      // setGraphTelemetryData(data);

      // console.log("api result", telemetryApiResult.data.result);
      // console.log("concat data", data.length);

    } else {
      handleApiFailureWithDialog(props.requestDialog, telemetryApiResult);
    }
  }


  if (!loaded) {
    return <PageLoadingView />;
  }
  return (
    <div className="paper-with-padding">
      <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
        <Grid item >
          <Typography variant="h4" component="h2" color="secondary">
            Device - {deviceId}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setEditingConfig(true);
            }}
          >
            <EditIcon />  Edit Config
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => {
              props.history.push('/');
            }}
          >
            <ArrowBackIosIcon />Back
          </Button>
        </Grid>
        <Grid item>
          <Select onChange={(e) => setDisplayDuration(e.target.value)} defaultValue={displayOption.at(-1)}>
            {displayOption.map((option) => {
              return <MenuItem value={option}>Last {option} hour</MenuItem>
            })}
          </Select>
        </Grid>
      </Grid>
      <Grid style={{ marginBottom: 8 }}>
        <Grid item>
          <Typography variant="h6" color="textSecondary">Name: {props.user.client !== 'shk' ? device.name : device.shk_name}</Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
            <Grid item>
            { props.user.client !== 'shk' ?
              <Typography variant="h6" color="textSecondary">Location: {device.location}</Typography> :
              <><Typography variant="h6" color="textSecondary">Floor: {device.shk_floor}</Typography>
              <Typography variant="h6" color="textSecondary">Sector: {device.shk_sector}</Typography></>
            }
            </Grid>
            <Grid item xs>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setEditingLoaction(true);
                }}
              >
                <EditIcon fontSize='small' />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item>
          <Typography variant="h6" color="textSecondary">Account: {device.email}</Typography>
        </Grid> */}
      </Grid>
      
      <Grid conainer>
      {props.user.role !== 12 || true ? <></> : (
        Object.keys(snapData).length === 0 ? <></> :
        <Grid item sm={2} md={2}>
          {
            Object.keys(snapData).filter(key => device.dataColumns.includes(key)).map(key => (
              <Grid>
                {dataPropsDisplayName[key]}
              </Grid>
            ))
          }
        </Grid>
      )}
      </Grid>
      <Grid style={{display:"flex", flexWrap:"wrap"}}>
        {dataProps
        .filter(key => !device.dataColumns || device.dataColumns.length === 0 || device.dataColumns.includes(key))
        .map((data, i) => { 
          if(!(telemetryData.every(obj => obj[data] === null))){
            const dataMax = Math.max(...telemetryData.map(obj => parseFloat(obj[data])));
          return (
          <ResponsiveContainer width='49%' height={400}>
          <LineChart syncId={'sync'} cx={150} cy={150} innerRadius={20} outerRadius={140} 
              data={[...graphTelemetryData].sort((c, d) => c.timestamp.localeCompare(d.timestamp)).filter(d => moment().diff(moment(d.timestamp, 'YYYY-MM-DDTHH:mm:ss'), 'h') < displayDuration)}
              margin={{ top: 20, bottom: 5, left: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="timestamp" tickFormatter={(unixTime) => moment(unixTime).format('HH:mm')}/>
              <YAxis domain={[0, dataMax]} >
                <Label angle={-90} value={'Value ' + dataPropsUnit[data]} position='insideLeft' style={{textAnchor: 'middle'}} />
              </YAxis>
              <Tooltip />
              <Legend />
              <Line type="linear" dataKey={data} stroke={color[i]} dot={false} name={dataPropsDisplayName[data]}/>
              <ReferenceLine y={gdrefLineVal[refIndex.indexOf(data)]} stroke="red" label={{position: 'insideBottomRight', value:"Good"}}> <Label value={gdrefLineVal[refIndex.indexOf(data)]} position="insideBottomLeft"/></ReferenceLine>
              <ReferenceLine y={exrefLineVal[refIndex.indexOf(data)]} stroke="red" label={{position: 'insideBottomRight', value:"Excellent"}}> <Label value={exrefLineVal[refIndex.indexOf(data)]} position="insideBottomLeft"/></ReferenceLine>
            </LineChart>
          </ResponsiveContainer>
        )
      }})}
      </Grid>
        {/*<ResponsiveContainer width='100%' height={400}>
        <LineChart syncId={'sync'} height={400} data={[...graphTelemetryData].sort((c, d) => c.timestamp.localeCompare(d.timestamp)).filter(d => moment().diff(moment(d.timestamp, 'YYYY-MM-DDTHH:mm:ss'), 'h') < displayDuration)}
            margin={{ top: 20, bottom: 5, left: 0, right: 10 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp" tickFormatter={(unixTime) => moment(unixTime).format('HH:mm Do')}/>
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="temperature" stroke="#8884d8" dot={false}/>
            <Line type="monotone" dataKey="humidity" stroke="#82ca9d" dot={false}/>
            <Line type="monotone" dataKey="pm10" stroke="#82ca00" dot={false}/>
            <Line type="monotone" dataKey="pm25" stroke="#5584d8" dot={false}/>
            <Line type="monotone" dataKey="co" stroke="#1084d8" dot={false}/>
            <Line type="monotone" dataKey="co2" stroke="#3084d8" dot={false}/>
            <Line type="monotone" dataKey="voc" stroke="#55ca00" dot={false}/>
            <Line type="monotone" dataKey="hcn" stroke="#c7c227" dot={false}/>
            {
            //<ReferenceLine y={50} stroke="red"> <Label value="50" position="insideBottomLeft"/></ReferenceLine>
            }
          </LineChart>
          </ResponsiveContainer>*/}
      

      <Grid>
      <DeviceTelemetryTable data={telemetryData} type={device.type} loaded={telemetryInitLoaded}/>
      <DeviceConfigDrawer
        device={device} 
        editingConfig={editingConfig}
        onBeforeCloseHandler={(updatedDevice)=>{
          if(updatedDevice){
            setDevice(updatedDevice);
          }
          setEditingConfig(false);
        }}
      />
      </Grid>
      <DeviceLocationEditDialog
        device={device} 
        open={editingLocation}
        client={props.user.client}
        onBeforeCloseHandler={(updatedDevice)=>{
          if(updatedDevice){
            setDevice(updatedDevice);
          }
          setEditingLoaction(false);
        }}
      />
    </div>
  )

}


const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};


export default connect(mapStateToProps, null)(withDialog(withSnackbar(DevicePage)));
