import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { TextField, Grid, Button, Paper, Typography, FormControl, List, ListItem, ListItemText } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';
import { IAQ_TYPE, EM_TYPE } from '../../utils/constants';
import toHKTimeString from '../../utils/to-hk-time-string';

import PageLoadingView from '../../components/PageLoadingView/PageLoadingView';
import PageErrorView from '../../components/PageErrorView/PageErrorView';

import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';
import AccountConfigDrawer from '../../containers/AccountConfigDrawer/AccountConfigDrawer';

const AccountDetailPage = props => {
  const email = props.match.params.email;
  const history = props.history;
  const [loaded, setLoaded] = useState(false);

  const [error, setError] = useState(null);
  const [editingConfig, setEditingConfig] = useState(false);

  const [account, setAccount] = useState(null);

  const refproperty = ["Pm10 (μg/m3)", "Pm2.5 (μg/m3)", "Co (ppm)", "Co2 (ppm)", "TVoc (ppb)", "O3 (ppm)"];
  const gdrefpropertyName = ["pm10GdRef", "pm25GdRef", "coGdRef", "co2GdRef", "vocGdRef", "ozoGdRef"];
  const exrefpropertyName = ["pm10ExRef", "pm25ExRef", "coExRef", "co2ExRef", "vocExRef", "ozoExRef"];

  useEffect(() => {
    fetchAccountData();
  }, []);

  const fetchAccountData = async () => {
    setLoaded(false);
    let accountApiResult = await api('get', `${envars.userServiceUrl}/account/${email}`, null);
    if (accountApiResult.data.success) {
      let accountResult = accountApiResult.data.result;
      let accountDisaviable = false;
      if(props.user.role !== 3){
        if((props.user.role !== 2 || props.user.role !== 12) && props.user.client !== undefined && props.user.client !== accountResult.client){
          accountDisaviable = true;
        }
        else if(props.user.client === undefined && props.user.email !== email){
          accountDisaviable = true;
        }
      }
      if(accountResult.deleted === 1){
        accountDisaviable = true;
      }
      if (accountDisaviable){
        return props.requestDialog({
          title: 'Session expired',
          text: 'Please login again',
          buttons: [{
            text: 'ok',
            onClick: () => {
              sessionStorage.clear();
              window.location.reload();
              return;
            }
          }],
        });
      }
      setAccount(accountResult);
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, accountApiResult);
    }
  }

  const getRoleName = (role) => {
    switch (role) {
        case 1: return "User";
        case 2: return "Admin";
        case 3: return "Super Admin";
        case 11: return "SHK user";
        case 12: return "SHK Admin";
        default: return "";
    }
  }

  const getGraphDisplayRange = (graphDisplayRange) => {
    if (graphDisplayRange && graphDisplayRange.length > 0){
      return graphDisplayRange.join(' Hour, ') + ' Hour';
    }
    else{
      return null;
    }
  }

  if (!loaded) {
    return <PageLoadingView />;
  }

  return (
    <div className="paper-with-padding">
    <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
      <Grid item >
        <Typography variant="h4" component="h2" color="secondary">
          Account Information
        </Typography>
      </Grid>
      {props.user.role === 3 || props.user.role === 12  || props.user.role === 2 ?
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setEditingConfig(true);
          }}
        >
          <EditIcon />  Edit Config
        </Button>
      </Grid>
      : null }
      <Grid item>
        <Button
          variant="contained"
          onClick={() => {
            props.history.push('/accounts');
          }}
        >
          <ArrowBackIosIcon />Back
        </Button>
      </Grid>
    </Grid>
    <Grid style={{ marginBottom: 8, border: '2px solid lightgray', borderRadius: '30px', padding: '15px' }}>
      <Typography variant="h5" component="h2" color="black">
          Basic Info:
      </Typography>
      <Grid item>
        <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
          <Grid item xs={5} sm={5}>
            <Typography variant="h6" color="textSecondary">Email/Login ID</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textSecondary">{account.email}</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
          <Grid item xs={5} sm={5}>
            <Typography variant="h6" color="textSecondary">First Name</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textSecondary">{account.firstName && account.firstName !== "" ? account.firstName : "-"}</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
          <Grid item xs={5} sm={5}>
            <Typography variant="h6" color="textSecondary">Last Name</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textSecondary">{account.lastName && account.lastName !== "" ? account.lastName : "-"}</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
          <Grid item xs={5} sm={5}>
            <Typography variant="h6" color="textSecondary">Role</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textSecondary">{getRoleName(account.role)}</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
          <Grid item xs={5} sm={5}>
            <Typography variant="h6" color="textSecondary">Admin portal access</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textSecondary">{account.adminPortalAccess === 1 ? "Yes" : "No"}</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
          <Grid item xs={5} sm={5}>
            <Typography variant="h6" color="textSecondary">Receive alert email</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textSecondary">{account.alertEmail && account.alertEmail !== "" ? account.alertEmail : "-"}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item style={{marginTop: 20, border: '2px solid lightgray', borderRadius: '30px', padding: '15px' }}>
      <Typography variant="h5" component="h2" color="black">
          Device Info:
      </Typography>
      <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
        <Grid item xs={5} sm={5}>
          <Typography variant="h6" color="textSecondary">
              Device ID
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textSecondary">
              <List>
              {account.devices && account.devices.length > 0 ? account.devices.map((item, index) => <ListItem key={index}>{item}</ListItem>) : <Typography variant="h6" color="textSecondary">-</Typography>  }
                  {/* {account.devices.map((item, index) => <ListItem key={index}>{item}</ListItem>)} */}
              </List>
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
        <Grid item xs={5} sm={5}>
          <Typography variant="h6" color="textSecondary">Data Frequency</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textSecondary">{getGraphDisplayRange(account.graphDisplayRange) ?? "-"}</Typography>
        </Grid>
      </Grid> 
    </Grid>
    <Grid item style={{marginTop: 20, border: '2px solid lightgray', borderRadius: '30px', padding: '15px' }}>
      <Typography variant="h5" component="h2" color="black">
          Reference Line Value (for Display only):
      </Typography>
      <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
        <Grid item xs={4} sm={4}>
          <Typography variant="h6" color="textSecondary">
              Parameters
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant="h6" color="textSecondary">
              Good
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant="h6" color="textSecondary">
              Excellent
          </Typography>
        </Grid>
      </Grid>
      {refproperty.map((val, i) => {
        return (
        <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
          <Grid item xs={4} sm={4}>
            <Typography variant="h6" color="textSecondary">{val}</Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography variant="h6" color="textSecondary">{account.gdRefLine[gdrefpropertyName[i]] ?? "-"}</Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography variant="h6" color="textSecondary">{account.exRefLine[exrefpropertyName[i]] ?? "-"}</Typography>
          </Grid>
        </Grid> 
      )})}
    </Grid>
    <AccountConfigDrawer
      account={account} 
      editingConfig={editingConfig}
      history={history}
      onBeforeCloseHandler={(updatedAccount)=>{
        if(updatedAccount){
          setAccount(updatedAccount);
        }
        setEditingConfig(false);
      }}
    />
  </div>
)

}


const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};


export default connect(mapStateToProps, null)(withDialog(withSnackbar(AccountDetailPage)));
