import ExcelJS from "exceljs";
import { Buffer } from 'buffer';

// want to make an OO version class;
class Xlsx {
    constructor() {
        this.workbook = new ExcelJS.Workbook();
        this.workbook.views = [
            {
            x: 0, y: 0, width: 10000, height: 20000,
            firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
        ];
    }

    addWorksheet(worksheetName) {
        this.worksheet = this.workbook.addWorksheet(worksheetName);
    }


}

const convertToColumn = (header, displayHeader, width = 15, hidden = false) => {
    return {
        header: displayHeader,
        key: header,
        width: width,
        hidden: hidden
    }
}

const generateXlsxStream = async (data, headers, columns, worksheetName) => {
    if (!Array.isArray(data)) {
        return {
            result: false,
            msg: "data is not an array",
            body: null
        };
    }
    
    const workbook = new ExcelJS.Workbook();
    workbook.views = [
        {
        x: 0, y: 0, width: 10000, height: 20000,
        firstSheet: 0, activeTab: 1, visibility: 'visible'
        }
    ];
    const worksheet = workbook.addWorksheet(worksheetName);
    if (!headers) {
        headers = Object.keys(data[0]);
    }
    if (!columns) {
        columns = headers.map(head => { 
            return convertToColumn(head, head);
        })
    }
    worksheet.columns = columns;
    data.forEach((dataRow, index) => {
        const row = worksheet.getRow(index+2);
        row.values = {
            ...dataRow
        }
    });
    const stream = await workbook.xlsx.writeBuffer();
    return (stream.toString("base64"));
}

const getXlsxAsStream = async (data, header = null, columns = null, worksheetName = "sheet1") => {
    return await generateXlsxStream(data, header, columns, worksheetName);
}

const getXlsxAsBlob = async (data, header = null, columns = null, worksheetName = "sheet1") => {
    data = await generateXlsxStream(data, header, columns, worksheetName);
    const byteArray = Buffer.from(data, "base64");
    return new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
}

export { getXlsxAsStream, getXlsxAsBlob, convertToColumn };