import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid, Paper, Typography, TextField, TablePagination, InputAdornment, FormControl, InputLabel, Select, MenuItem, Button
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import SubjectIcon from '@material-ui/icons/Subject';

import PageLoadingView from '../../components/PageLoadingView/PageLoadingView';
import PageErrorView from '../../components/PageErrorView/PageErrorView';
import DeviceCard from '../../components/DeviceCard/DeviceCard';
import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';
import envars from '../../envars';
import DescriptionIcon from '@material-ui/icons/Description';

const IPDomainPage = props => {

    const [IP, setIP] = useState('');


    return (
        <div className="paper-with-padding">
            <Grid container spacing={1} justify="flex-start">
                <Grid item>
                    <Typography variant="h4" component="h2">
                    IP Domain
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                <TextField
                    label='IP Domain'
                    id='IpDomain'
                    name='IpDomain'
                    required
                    value={IP}                     
                    onChange={(e) => {setIP(e.target.value)}}
                    fullWidth
                />
                </FormControl>
                <Grid item xs={12} sm={12}>
                  <Grid container justify="flex-end">
                    <Button

                      color="secondary"
                      variant="contained"
                    >
                      <DescriptionIcon />
                    Save
                  </Button>
                  </Grid>
                </Grid>
            </Grid> 
         
        </div>
    )
}
const mapStateToProps = state => {
    return {
      user: state.system.user
    };
  };

export default connect(mapStateToProps, null)(withDialog(withSnackbar(IPDomainPage)));