import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Grid, Paper, FormControlLabel, Typography, FormControl, TextField, InputLabel, Button, Select, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';
import toHKTimeString from '../../utils/to-hk-time-string';

import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';
import '../../components/Dialog/Dialog.css';
import { confirmAlert } from 'react-confirm-alert';

const AccountConfigDrawer = props => {
  const { account, editingConfig, onBeforeCloseHandler } = props;
  const [error, setError] = useState({ key: null, msg: null });
  const [loaded, setLoaded] = useState(false);
  
  const [accountChange, setAccount] = useState(account);
  const [devices, setDevices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterDevices, setFilterDevices] = useState([]);
  const [graphDisplayRange, setGraphDisplayRange] = useState(account.graphDisplayRange ?? []);

  const refproperty = ["Pm10 (μg/m3)", "Pm2.5 (μg/m3)", "Co (ppm)", "Co2 (ppm)", "TVoc (ppb)", "O3 (ppm)"];
  const gdrefpropertyName = ["pm10GdRef", "pm25GdRef", "coGdRef", "co2GdRef", "vocGdRef", "ozoGdRef"];
  const exrefpropertyName = ["pm10ExRef", "pm25ExRef", "coExRef", "co2ExRef", "vocExRef", "ozoExRef"];
  const allowNull = ["firstName", "lastName", "adminPortalAccess", "alertEmail"];

  useEffect(() => {
    fetchDeviceData();
  }, []);
//Can delete when service updated
  if(accountChange.gdRefLine === undefined){
    accountChange.gdRefLine = {};
  }
  if(accountChange.exRefLine === undefined){
    accountChange.exRefLine = {};
  }
  //  

  const fetchDeviceData = async () => {
    setLoaded(false);
    let deviceApiResult = await api('get', `${envars.deviceServiceUrl}/devices`, null);
    if (deviceApiResult.data.success) {
      let device = deviceApiResult.data.result.devices;
      setDevices(device);
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, deviceApiResult);
    }
  }

  const filterDeviceBySearch = (device) => {
    setSearchTerm(device);
    let filterList = devices.filter(de => de.device_id.indexOf(device) > -1);
    setFilterDevices(filterList);
  }

  const onSaveButtonHandler = async () => {
    accountChange.devices = isCheck;
    const newAccount = accountChange;

    for (let i = 0; i < gdrefpropertyName.length; i++) {
      if((isNaN(accountChange.gdRefLine[gdrefpropertyName[i]]) && accountChange.gdRefLine[gdrefpropertyName[i]] !== undefined && accountChange.gdRefLine[gdrefpropertyName[i]] !== '') || accountChange.gdRefLine[gdrefpropertyName[i]] < 0){
        setError({ 'key': gdrefpropertyName[i], 'msg': gdrefpropertyName[i] + ' need to be positive number or empty' });
        return;
      }     
      if((isNaN(accountChange.exRefLine[exrefpropertyName[i]]) && accountChange.exRefLine[exrefpropertyName[i]] !== undefined && accountChange.exRefLine[exrefpropertyName[i]] !== '') || accountChange.exRefLine[exrefpropertyName[i]] < 0){
        setError({ 'key': exrefpropertyName[i], 'msg': exrefpropertyName[i] + ' need to be positive number or empty' });
        return;
      }    
    }
    
    for (let key of Object.keys(newAccount)) {
      if ((newAccount[key] === '' || newAccount[key] === null) && (!allowNull.includes(key) && !gdrefpropertyName.includes(key))) {
        setError({ 'key': key, 'msg': `Please Input ${key}` });
        return;
      }
    }

    setError({ key: null, msg: null });

    let addAccountApiResult = await api('post', `${envars.userServiceUrl}/account/update`, newAccount);
    if (addAccountApiResult.data.success) {
      props.makeSnackbar('Update Account Successfully.')
      let updatedAccount = addAccountApiResult.data.result.account;
      // console.log(newDevice);
      onBeforeCloseHandler(updatedAccount);
    } else {
      handleApiFailureWithDialog(props.requestDialog, addAccountApiResult);
    }


  }

  function onDeleteButtonHandler() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
        <Paper className="paper-with-padding dialog">
          <Typography gutterBottom>Are you sure to delete this account?</Typography>
            <Button
              variant="contained"
              onClick={() => {
                deleteAccount();
                onClose();
              }}
            >
              Yes
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              onClick={onClose}
            >
              No
            </Button>
        </Paper>
      )}
    });
  }

  const deleteAccount = async () => {
    let deleteAccountApiResult = await api('post', `${envars.userServiceUrl}/account/delete`, account);
    if (deleteAccountApiResult.data.success) {
      props.makeSnackbar('Delete Account Successfully.')
      props.history.push('/accounts');
    } else {
      handleApiFailureWithDialog(props.requestDialog, deleteAccountApiResult);
    }
  }

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState(account.devices ? account.devices : []);
  
  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(devices.map(li => li.deviceId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
    const { value, checked } = e.target;
    setIsCheck([...isCheck, value]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== value));
      setIsCheckAll(false);
    }
  };

  const listItems = devices.map((device) =>
  <label style={{fontSize:16}}><input type="checkbox" id={devices.indexOf(device)} value={device.deviceId} 
          onChange={handleClick}
          checked={isCheck.includes(device.deviceId)}
          disabled={props.user.role !== 3 && props.account.email === props.user.email}
          />
          {device.deviceId}
  <br/></label>
  );

  function hrsHandle(e)
  {
    const hr_arr = graphDisplayRange
    if (e.target.checked)
    {
      if (hr_arr.length > 0 && hr_arr.includes(e.target.value))
      {  }
      else 
      {
        hr_arr.push(Number(e.target.value))
        setAccount({...accountChange, graphDisplayRange: hr_arr})
      }
    }
    else
    {
      if (hr_arr.length > 0)
      {
        for(let i = 0; i < hr_arr.length; i++)
        {
          if (hr_arr[i] === Number(e.target.value))
          {
            hr_arr.splice(i, 1)
            setAccount({...accountChange, graphDisplayRange: hr_arr})
            break
          }
        }
      }
    }
    graphDisplayRange.sort((a, b) => { return a - b});
  }

  const useStyles = makeStyles({
    list: {
      whiteSpace: "normal",
      maxWidth: "448px",
      minWidth: "416px"
    }
  });
  
  const classes = useStyles();

  return (
    <Drawer anchor="right" open={editingConfig} onClose={() => { onBeforeCloseHandler() }}>
      <Paper className="drawer-on-right paper-with-padding">
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h5">Account Config</Typography>
          </Grid>
          <Grid item>
            <Paper className="paper-with-padding">
              <Typography variant="h6" gutterBottom>
                Update
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      label='Email / Login ID'
                      id='email'
                      name='email'
                      autoFocus
                      required
                      value={accountChange.email}
                      error={error.key === 'email' ? true : false}
                      onChange={(e) => { setAccount({...accountChange, email: e.target.value })}}
                      fullWidth
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      label='First Name'
                      id='firstName'
                      name='firstName'
                      autoFocus
                      //required
                      value={accountChange.firstName}
                      error={error.key === 'firstName' ? true : false}
                      onChange={(e) => { setAccount({...accountChange, firstName: e.target.value })}}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      label='Last Name'
                      id='lastName'
                      name='lastName'
                      autoFocus
                      //required
                      value={accountChange.lastName}
                      error={error.key === 'lastName' ? true : false}
                      onChange={(e) => { setAccount({...accountChange, lastName: e.target.value })}}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                { props.user.role === 3 ?
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='Client'
                      id='client'
                      name='client'                      
                      required
                      value={accountChange.client}
                      error={error.key === 'client' ? true : false}
                      onChange={(e) => { setAccount({...accountChange, client: e.target.value })}}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                : null }
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Role
                    </InputLabel>
                    <Select
                      label='Role'
                      id='role'
                      name='role'
                      select
                      required
                      value={accountChange.role}
                      error={error.key === 'role' ? true : false}
                      onChange={(e) => { setAccount({...accountChange, role: e.target.value, adminPortalAccess: e.target.value > 1 ? 1 : 0 })}}
                      fullWidth
                      disabled={accountChange.role === 3 || accountChange.role === 11 || accountChange.role === 12}
                    >
                      <MenuItem key={"1"} value={'1'}>User</MenuItem>
                      <MenuItem key={'2'} value={'2'} disabled={props.user.role !== 3}>Admin</MenuItem>
                      <MenuItem key={'3'} value={'3'} disabled>Super Admin</MenuItem>
                      <MenuItem key={'11'} value={'11'} disabled>SHK User</MenuItem>
                      <MenuItem key={"12"} value={'12'} disabled>SHK Admin</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='Admin portal access'
                      id='adminPortalAccess'
                      name='adminPortalAccess'
                      select
                      //required
                      value={accountChange.adminPortalAccess}
                      error={error.key === 'adminPortalAccess' ? true : false}
                      helperText={error.key === 'adminPortalAccess' ? error.msg : null}
                      onChange={(e) => { setAccount({...accountChange, adminPortalAccess: e.target.value })}}
                      fullWidth
                      disabled={accountChange.role > 1 && accountChange.role !== 11}
                    >
                      <MenuItem key={"0"} value={'0'}>No</MenuItem>
                      <MenuItem key={'1'} value={'1'}>Yes</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <TextField
                      label='Alert email'
                      id='alertEmail'
                      name='alertEmail'                     
                      //required
                      value={accountChange.alertEmail}
                      error={error.key === 'alertEmail' ? true : false}
                      helperText={error.key === 'alertEmail' ? error.msg : null}
                      onChange={(e) => { setAccount({...accountChange, alertEmail: e.target.value })}}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom>
                    Reference Line Value
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="h6" gutterBottom>
                    Good
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="h6" gutterBottom>
                    Excellent
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                  {refproperty.map((ref, i) => {
                  return (
                    <FormControl required fullWidth>
                      <TextField
                        label={ref}
                        id={ref}
                        name={ref}                     
                        //required
                        value={accountChange.gdRefLine[gdrefpropertyName[i]]}
                        error={error.key === gdrefpropertyName[i] ? true : false}
                        helperText={error.key === gdrefpropertyName[i] ? error.msg : null}
                        //onChange={(e) => { setAccount({...accountChange, [gdrefpropertyName[i]]: e.target.value })}}
                        onChange={(e) => { setAccount({
                          ...accountChange,
                          gdRefLine:{
                            ...accountChange.gdRefLine,
                              [gdrefpropertyName[i]]: e.target.value
                          }
                         })}}
                        fullWidth
                      />
                    </FormControl>
                  )})}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                  {refproperty.map((ref, i) => {
                  return (
                    <FormControl required fullWidth>
                      <TextField
                        label={ref}
                        id={ref}
                        name={ref}                     
                        //required
                        value={accountChange.exRefLine[exrefpropertyName[i]]}
                        error={error.key === exrefpropertyName[i] ? true : false}
                        helperText={error.key === exrefpropertyName[i] ? error.msg : null}
                        //onChange={(e) => { setAccount({...accountChange, [exrefpropertyName[i]]: e.target.value })}}
                        onChange={(e) => { setAccount({
                          ...accountChange,
                          exRefLine:{
                            ...accountChange.exRefLine,
                              [exrefpropertyName[i]]: e.target.value
                          }
                         })}}
                        fullWidth
                      />
                    </FormControl>
                  )})}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                  <Typography variant="h6" component="h0" justify="flex-start" gutterBottom>
                      Data Frequency:
                    </Typography>
                    <Typography variant="h6" component="h2" >
                    <label><input type="checkbox" id='1' value="1" checked={graphDisplayRange.includes(1)} onChange={hrsHandle} />1 hours &nbsp;&nbsp;</label>
                    <label><input type="checkbox" id='3' value="3" checked={graphDisplayRange.includes(3)} onChange={hrsHandle} />3 hours &nbsp;&nbsp;</label>
                    <label><input type="checkbox" id="8" value="8" checked={graphDisplayRange.includes(8)} onChange={hrsHandle} />8 hours &nbsp;&nbsp;</label>
                    <label><input type="checkbox" id="24" value="24" checked={graphDisplayRange.includes(24)} onChange={hrsHandle} />24 hours </label>
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl required fullWidth>
                    <Typography variant="h6" component="h0" justify="flex-start" gutterBottom>
                      Device ID
                      <Typography variant="h6" component="h2" >
                        <label style={{fontSize:16}}><input type="checkbox" id='selectAll' value="selectAll" onChange={handleSelectAll} checked={isCheckAll} disabled={props.user.role !== 3 && props.account.email === props.user.email} />Select all &nbsp;&nbsp;</label>
                        {/*<label><input type="checkbox" id="deselectAll" value="deselectAll" />Deselect all </label>*/}                      
                      </Typography>
                    </Typography>

                    <Typography variant="h6" component="h2" >                   
                    {listItems}
                    </Typography>
                    {/*<Checkbox id='1234' value='testing'>Testing</Checkbox> 
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />*/}
                  </FormControl>
                </Grid>

                {/*<Grid item xs={12} sm={12}>
                  <FormControl required >
                    <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Devices
                    </InputLabel>
                    <TextField
                      label='Search Device'
                      id='searchDevice'
                      name='searchDevice'
                      value={searchTerm}
                      onChange={(e) => { filterDeviceBySearch(e.target.value) }}
                      fullWidth
                    />
                    <Select
                    MenuProps={{className: classes.list}}
                    SelectDisplayProps={{className: classes.list}}
                      label='Devices'
                      id='devices'
                      name='devices'
                      required
                      multiple
                      value={accountChange.devices? accountChange.devices : []}
                      error={error.key === 'devices' ? true : false}
                      onChange={(e) => { setAccount({...accountChange, devices: e.target.value })}}
                      fullWidth
                    >
                    {filterDevices.map((item, index) => <MenuItem className={classes.list} key={index} value={item.deviceId}>{item.deviceId}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>*/}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" spacing={2}>
          { props.user.email !== account.email ?
          <Grid item xs={12} sm={9}>
            <Button
              //fullWidth
              variant="contained"
              //color="secondary"
              style={{ color: 'white', backgroundColor: '#D0342C' }}
              onClick={onDeleteButtonHandler}
            >
              <DeleteIcon />
                Delete
              </Button>
          </Grid>
          : <Grid item xs={12} sm={9}></Grid>}
          <Grid item xs={12} sm={3}>
            <Button
              //fullWidth
              variant="contained"
              color="secondary"
              onClick={onSaveButtonHandler}
            >
              <SaveIcon />
                Save
              </Button>
          </Grid>
        </Grid>
      </Paper>
    </Drawer>
  )
}


const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};


export default connect(mapStateToProps, null)(withDialog(withSnackbar(AccountConfigDrawer)));