import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid, Paper, Typography, Button, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Avatar, FormControl, TextField,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import SearchIcon from '@material-ui/icons/Search';
import SubjectIcon from '@material-ui/icons/Subject';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';
import useDebounce from '../../utils/useDebounce';
import PageLoadingView from '../../components/PageLoadingView/PageLoadingView';
import { dialog } from '../../components/Dialog/Dialog';
import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';
import { convertToColumn, getXlsxAsBlob } from "../../lib/lib-exceljs";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush, ReferenceArea, ReferenceLine, Label } from "recharts";

const SuperAdminChartPage = props => {
  const maxDate = new Date(new Date().toDateString());

  const current = new Date(new Date().toDateString());
  const minDate = new Date(current.setDate(current.getDate() - 7));

  const [exportFileClick, setExportFileClick] = useState(false);
  const [filePreparing, setFilePreparing] = useState(false);
  const [to, setTo] = useState(maxDate);
  const [from, setFrom] = useState(minDate);
  const [deviceId, setDeviceId] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [graphDataLoaded, setGraphDataLoaded] = useState(false);

  const [ConfigTo, setConfigTo] = useState(maxDate);
  const [ConfigFrom, setConfigFrom] = useState(minDate);

  const [IPConfig, setIPConfig] = useState('');

  const [IAQFileName, setIAQFileName] = useState(null);
  const [EMFileName, setEMFileName] = useState(null);
  const [IAQTempUrl, setIAQTempUrl] = useState(null);
  const [EMTempUrl, setEMTempUrl] = useState(null);
  const [IAQExport, setIAQExport] = useState(false);
  const [IEQExport, setIEQExport] = useState(false);

  const [lastFetchTime, setLastFetchTime] = useState(0);
  const [deviceList, setDeviceList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  // const dateDisabled = date => (date.getTime() < minDate.getTime() || date.getTime() > maxDate.getTime());
  const dateDisabled = date => (false);

  const [searchLocation, setSearchLocation] = useState('');
  const [searchDeviceId, setSearchDeviceId] = useState('');
  const [searchType, setSearchType] = useState('');
  const debouncedLocation = useDebounce(searchLocation, 200);
  const debouncedDeviceId = useDebounce(searchDeviceId, 200);
  const debouncedType = useDebounce(searchType, 200);

  const dataProps = ["temperature", "humidity", "pm10", "pm25", "co", "co2", "voc", "hcn", "AirVelocity", "AirTemperature", "SoundLevel", "light", "RadTemperature", "ozo"];
  const dataPropsDisplayName = {
    "temperature":"Temperature"
    ,"humidity":"Humidity"
    ,"pm10":"PM 10"
    ,"pm25":"PM 2.5"
    ,"co":"CO"
    ,"co2":"CO2"
    ,"voc":"TVOC"
    ,"hcn":"HCN"
    ,"AirVelocity":"Air Velocity"
    ,"AirTemperature":"Air Temperature"
    ,"SoundLevel":"SoundLevel"
    ,"light":"light"
    ,"RadTemperature":"RadTemperature"
    ,"ozo":"O3"
  };
  const dataPropsUnit = {
    "temperature":"(°C)"
    ,"humidity":"(%)"
    ,"pm10":"(μg/m3)"
    ,"pm25":"(μg/m3)"
    ,"co":"(ppm)"
    ,"co2":"(ppm)"
    ,"voc":"(ppb)"
    ,"hcn":"(μg/m3)"
    ,"AirVelocity":"(m/s)"
    ,"AirTemperature":"(°C)"
    ,"SoundLevel":"(dB)"
    ,"light":"(lm)"
    ,"RadTemperature":"(°C)"
    ,"ozo":"(ppm)"
  };
  const color = ["#8884d8", "#82ca9d", "#82ca00", "#5584d8", "#1084d8", "#3084d8", "#55ca00", "#c7c227"];
  const iaqSelectAttributes = {
    "deviceId": "deviceId",
    "name": "name",
    "location": "location",
    "timestamp": "timestamp",
    "temperature": "Temperature on board (°C)",
    "humidity": "Humidity (%)",
    "pm10": "PM10 (μg/m3)",
    "pm25": "PM2.5 (μg/m3)",
    "co": "CO(ppm)",
    "co2": "CO2 (ppm)",
    "voc": "TVOC(ppb)",
    "hcn": "HCN(μg/m3)",
    "AirVelocity": "AirVelocity(m/s)",
    "AirTemperature": "Air Temperature(°C)",
    "SoundLevel": "SoundLevel(dB)",
    "light": "Light(lm)",
    "RadTemperature": "RadTemperature(°C)",
    "ozo": "O3(ppm)"
  };

  const displayButtonClick = async (e) => {
    // setExportFileClick(true);
    // setFilePreparing(true);
    // let temp = deviceList.filter(item => isCheck.includes(item.deviceId));
    // setIAQExport(false);
    // setIEQExport(false);
    // temp.map(i => {
    //   if (i.type === 'IAQ' || i.type === 'IEQ') {
    //     setIAQExport(true);
    //   }
    //   if (i.type === 'EM') {
    //     setIEQExport(true);
    //   }
    // })

    // await Promise.all([exportIAQData(), exportEMData()]);
    // setFilePreparing(false);
    let querys = [];
    querys.push(`from=${from.getTime()}`);
    querys.push(`to=${to.getTime()}`);
    querys.push(`devices=${deviceId}`);
    querys.push(`display=Default`);
    const options = {
      //responseType: 'blob',
    };
    setGraphDataLoaded(false);

    let exportDataApiResult = await api('get', `${envars.telemetryServiceUrl}/telemetry/csv/v2/iaqstream?${querys.join('&')}`, null, options);
    if (exportDataApiResult.data) {
      let tempData = JSON.parse("[" + (exportDataApiResult.data).replaceAll("}\n{","},{") + "]");
      tempData = tempData.sort((c, d) => c.timestamp.localeCompare(d.timestamp));
      tempData.forEach(datum => { datum.timestamp = new Date(datum.timestamp).valueOf()})
      setGraphData(tempData);
      setGraphDataLoaded(true);
    }
  }

  useEffect(() => {
    if (!loaded) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedLocation, debouncedDeviceId, debouncedType]);

  const fetchData = _.debounce(async () => {
    setLoaded(false);
    if (new Date().valueOf() - lastFetchTime > 60000) {
      let deviceApiResult = await api('get', `${envars.deviceServiceUrl}/devices`, null);
      if (deviceApiResult.data.success) {
        let deviceList = deviceApiResult.data.result.devices;
        setDeviceList(deviceList);
        setLastFetchTime(new Date().valueOf());
      }
    }
    setLoaded(true);
  }, 500);

  const filterData = (data) => {
    let filterData = [...data];
    if (debouncedLocation !== "") {
      filterData = filterData.filter(d =>
        d.location.includes(debouncedLocation))
    }
    if (debouncedDeviceId !== "") {
      filterData = filterData.filter(d =>
        d.deviceId.includes(debouncedDeviceId))
    }
    if (debouncedType !== "") {
      filterData = filterData.filter(d => d.type !== undefined &&
        d.type.includes(debouncedType))
    }
    return filterData;
  }

  const DisplayChart = () => {
    if (graphData.length === 0) {
      return null;
    } else {
      let dataColumns = deviceList.find(device => device.deviceId === deviceId).dataColumns;
      // return  (
      //       <ResponsiveContainer key="temperature" width='49%' height={400}>
      //         <LineChart syncId={'sync'} cx={150} cy={150} innerRadius={20} outerRadius={140} 
      //           data={[...graphData]}
      //           margin={{ top: 20, bottom: 5, left: 20 }}>
      //           <CartesianGrid strokeDasharray="3 3" />
      //           <XAxis dataKey="timestamp" tickFormatter={(unixTime) => moment(unixTime).format('HH:mm')}/>
      //           <YAxis>
      //             <Label angle={-90} value={'Value ' + dataPropsUnit["temperature"]} position='insideLeft' style={{textAnchor: 'middle'}} />
      //           </YAxis>
      //           <Tooltip />
      //           <Legend />
      //           <Line type="linear" dataKey={"temperature"} stroke={color[0]} dot={false} name={dataPropsDisplayName["temperature"]}/>
      //         </LineChart>
      //       </ResponsiveContainer>
      //     )
      return Object.keys(graphData[0])
      .filter(key => !dataColumns || dataColumns.length === 0 || dataColumns.includes(key))
      .map((key, i) => {
        if(!(graphData.every(obj => obj[key] === null))) {
          // const dataMax = Math.max(...key.map(obj => parseFloat(obj[key])));
          return (
            <ResponsiveContainer key={key} width='49%' height={400}>
            <LineChart syncId={'sync'} cx={150} cy={150} innerRadius={20} outerRadius={140} 
                data={[...graphData]}
                margin={{ top: 20, bottom: 5, left: 20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" type = 'number' domain = {['auto', 'auto']} 
                tickFormatter = {(unixTime) => moment(unixTime).format('MM:DD HH:mm')}/>
                <YAxis>
                  <Label angle={-90} value={'Value ' + dataPropsUnit[key]} position='insideLeft' style={{textAnchor: 'middle'}} />
                </YAxis>
                <Tooltip
                  labelFormatter={value => moment(value).format('MM:DD HH:mm')} 
                />
                <Legend />
                <Line type="linear" dataKey={key} stroke={color[i]} dot={false} name={dataPropsDisplayName[key]}/>
              </LineChart>
            </ResponsiveContainer>
          )
        }
      })
    }
  }


  return (
    <div className="paper-with-padding">
      <Grid container spacing={1} justify="flex-start">
        <Grid item>
          <Typography variant="h4" component="h2">
            View Chart
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <Paper className="paper-with-padding">
            <Typography variant="h6" component="h2">
              Duration
            </Typography>
            <Grid container spacing={3} alignItems='center'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={4}>
                  <KeyboardDateTimePicker
                    id='start-from'
                    variant="inline"
                    ampm={false}
                    label="Start From"
                    value={from}
                    onChange={setFrom}
                    shouldDisableDate={dateDisabled}
                    format="yyyy/MM/dd HH:mm"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDateTimePicker
                    id='end-to'
                    variant="inline"
                    ampm={false}
                    label="End To"
                    value={to}
                    onChange={setTo}
                    shouldDisableDate={dateDisabled}
                    format="yyyy/MM/dd HH:mm"
                    margin="normal"
                    fullWidth
                  />
                </Grid>

              </MuiPickersUtilsProvider>

              <Grid item xs={12} sm={12}>
                <FormControl required fullWidth>
                  <Typography variant="h6" component="h2" >
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <FormControl required fullWidth>
                          <TextField
                            enabled
                            label='DeviceId filter'
                            id='deviceIdFilter'
                            name='deviceIdFilter'
                            values={searchDeviceId}
                            onChange={(e) => { setSearchDeviceId(e.target.value); }}
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              )
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl required fullWidth>
                          <TextField
                            enabled
                            label='Location filter'
                            id='locationFilter'
                            name='locationFilter'
                            values={searchLocation}
                            onChange={(e) => { setSearchLocation(e.target.value); }}
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              )
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                            Type
                          </InputLabel>
                          <Select
                            value={searchType}
                            onChange={(e) => { setSearchType(e.target.value); }}
                            displayEmpty
                            fullWidth
                            inputProps={{
                              name: "type",
                              id: "type-selector"
                            }}
                          >
                            <MenuItem value={''}>None</MenuItem>
                            <MenuItem value={'IAQ'}>IAQ</MenuItem>
                            <MenuItem value={'IEQ'}>IEQ</MenuItem>
                            <MenuItem value={'EM'}>EM</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {deviceId !== "" ?
                        <Grid item xs={12} sm={3}>
                          <Grid container justify="flex-end">
                            <Button
                              onClick={displayButtonClick}
                              color="secondary"
                              variant="contained"
                            >
                              <DescriptionIcon />
                              Display
                            </Button>
                          </Grid>
                        </Grid>
                        : null}
                    </Grid>
                  </Typography>

                  <Grid item xs={12} sm={12}>
                    <FormControl required fullWidth>
                      <TextField
                        label='Device ID'
                        id='deviceId'
                        name='deviceId'
                        select
                        required
                        value={deviceId}
                        onChange={(e) => { setDeviceId(e.target.value) }}
                        fullWidth
                      >
                        {filterData(deviceList).map((device) => (
                          <MenuItem key={device.deviceId} value={device.deviceId}>{device.deviceId}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                </FormControl>
              </Grid>

              <br />
            </Grid>

            <Grid style={{display:"flex", flexWrap:"wrap"}}>
              {!graphDataLoaded ? null : DisplayChart()}
            </Grid>


          </Paper>
        </Grid>
      </Grid>
      <br /><br />
    </div>

  )
}

const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(SuperAdminChartPage)));
