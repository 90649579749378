import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid, Paper, Typography, Button, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Avatar, FormControl, TextField,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import SearchIcon from '@material-ui/icons/Search';
import SubjectIcon from '@material-ui/icons/Subject';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import envars from '../../envars';
import api, { handleApiFailureWithDialog } from '../../utils/api';
import useDebounce from '../../utils/useDebounce';
import PageLoadingView from '../../components/PageLoadingView/PageLoadingView';
import { dialog } from '../../components/Dialog/Dialog';
import { withSnackbar } from '../../containers/SnackbarManager/SnackbarManager';
import { withDialog } from '../../containers/DialogManager/DialogManager';
import { convertToColumn, getXlsxAsBlob } from "../../lib/lib-exceljs";

const ExportDataPage = props => {
  const maxDate = new Date(new Date().toDateString());

  const current = new Date(new Date().toDateString());
  const minDate = new Date(current.setDate(current.getDate() - 7));

  const [exportFileClick, setExportFileClick] = useState(false);
  const [filePreparing, setFilePreparing] = useState(false);
  const [to, setTo] = useState(maxDate);
  const [from, setFrom] = useState(minDate);
  const [display, setDisplay] = useState('Default');

  const [ConfigTo, setConfigTo] = useState(maxDate);
  const [ConfigFrom, setConfigFrom] = useState(minDate);

  const [IPConfig, setIPConfig] = useState('');

  const [IAQFileName, setIAQFileName] = useState(null);
  const [EMFileName, setEMFileName] = useState(null);
  const [IAQTempUrl, setIAQTempUrl] = useState(null);
  const [EMTempUrl, setEMTempUrl] = useState(null);
  const [IAQExport, setIAQExport] = useState(false);
  const [IEQExport, setIEQExport] = useState(false);

  const [deviceList, setDeviceList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  // const dateDisabled = date => (date.getTime() < minDate.getTime() || date.getTime() > maxDate.getTime());
  const dateDisabled = date => (false);

  const [searchLocation, setSearchLocation] = useState('');
  const [searchDeviceId, setSearchDeviceId] = useState('');
  const [searchType, setSearchType] = useState('');
  const debouncedLocation = useDebounce(searchLocation, 200);
  const debouncedDeviceId = useDebounce(searchDeviceId, 200);
  const debouncedType = useDebounce(searchType, 200);

  const iaqSelectAttributes = {
    "deviceId": "deviceId",
    "name": "name",
    "location": "location",
    "timestamp": "timestamp",
    "temperature": "Temperature on board (°C)",
    "humidity": "Humidity (%)",
    "pm10": "PM10 (μg/m3)",
    "pm25": "PM2.5 (μg/m3)",
    "co": "CO(ppm)",
    "co2": "CO2 (ppm)",
    "voc": "TVOC(ppb)",
    "hcn": "HCN(μg/m3)",
    "AirVelocity": "AirVelocity(m/s)",
    "AirTemperature": "Air Temperature(°C)",
    "SoundLevel": "SoundLevel(dB)",
    "light": "Light(lm)",
    "RadTemperature": "RadTemperature(°C)",
    "ozo": "O3(ppm)"
  };

  useEffect(() => {
    if (!loaded) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedLocation, debouncedDeviceId, debouncedType]);

  const fetchData = _.debounce(async () => {
    setLoaded(false);
    let deviceApiResult = await api('get', `${envars.deviceServiceUrl}/devices`, null);
    if (deviceApiResult.data.success) {
      let deviceList = deviceApiResult.data.result.devices;
      //setDeviceList(deviceList);
      let filterDevices = filterData(deviceList);
      setDeviceList(filterDevices);
      setLoaded(true);
    }
  }, 500);

  const filterData = (data) => {
    let filterData = [...data];
    if (debouncedLocation !== "") {
      filterData = filterData.filter(d =>
        d.location.includes(debouncedLocation))
    }
    if (debouncedDeviceId !== "") {
      filterData = filterData.filter(d =>
        d.deviceId.includes(debouncedDeviceId))
    }
    if (debouncedType !== "") {
      filterData = filterData.filter(d => d.type !== undefined &&
        d.type.includes(debouncedType))
    }
    return filterData
  }

  const exportButtonClick = async (e) => {
    // comment out timerange constraint, Jim@20220207
    // const fromTs = moment(from, "x");
    // const toTs = moment(to, "x");
    // if (toTs.diff(fromTs) > 604800 * 1000) {
    //   dialog({ icon: 'times-circle', message: 'The maximum export date period is 7 days' });
    //   return;
    // }

    setExportFileClick(true);
    setFilePreparing(true);
    let temp = deviceList.filter(item => isCheck.includes(item.deviceId));
    setIAQExport(false);
    setIEQExport(false);
    temp.map(i => {
      if (i.type === 'IAQ' || i.type === 'IEQ') {
        setIAQExport(true);
      }
      if (i.type === 'EM') {
        setIEQExport(true);
      }
    })

    await Promise.all([exportIAQData(), exportEMData()]);
    setFilePreparing(false);
  }

  const exportIAQData = async () => {
    let querys = [];
    querys.push(`from=${from.getTime()}`);
    querys.push(`to=${to.getTime()}`);
    querys.push(`devices=${isCheck}`);
    querys.push(`display=${display}`);
    const options = {
      //responseType: 'blob',
    };

    let exportDataApiResult = await api('get', `${envars.telemetryServiceUrl}/telemetry/csv/v2/iaqstream?${querys.join('&')}`, null, options);
    //if (exportDataApiResult.data && exportDataApiResult.data.type === 'text/csv') {
    //  const disposition = exportDataApiResult.headers['content-disposition'];
    //  if (disposition && disposition.match(/attachment/)) {
    //    let filename = disposition.replace(/attachment;.*filename=/, '').replace(/"/g, '');
    //    const file = new Blob([exportDataApiResult.data]);
    //    const tempUrl = window.URL.createObjectURL(file);
    //    setIAQFileName(filename);
    //    setIAQTempUrl(tempUrl);
    //  }
    //}else{
    //  const reader = new FileReader();
    //  reader.readAsText(exportDataApiResult.data)
    //  reader.onload = () => {
    //    let errorResult = {}
    //    errorResult.data = JSON.parse(reader.result);
    //    handleApiFailureWithDialog(props.requestDialog, errorResult);
    //  }
    //}
    // if (exportDataApiResult.data.success) {
    //   if (exportDataApiResult.data.result.length > 0) {
    //     const iaqRows = exportDataApiResult.data.result;
    if (exportDataApiResult.data) {
      if (typeof (exportDataApiResult.data) === 'object') {
        exportDataApiResult.data = JSON.stringify(exportDataApiResult.data);
      }
      if (exportDataApiResult.data.split('\n').filter(row => !!row).length > 0) {
        const iaqRows = exportDataApiResult.data.split('\n').filter(row => !!row).map(row => JSON.parse(row));
        const headers = Object.keys(iaqSelectAttributes);
        for (let i = 0; i < headers.length; i++) {
          if (
            iaqRows.every(d => d[headers[i]] === null) ||
            iaqRows.every(d => d[headers[i]] === "")
          ) {
            headers.splice(i, 1);
            i--;
          }
        }
        const columns = headers.map(header => {
          switch (header) {
            case "deviceId":
              return convertToColumn(header, iaqSelectAttributes[header], 30);
            case "temperature":
              return convertToColumn(header, iaqSelectAttributes[header], 25);
            case "RadTemperature":
            case "AirTemperature":
            case "timestamp":
              return convertToColumn(header, iaqSelectAttributes[header], 20);
            default:
              return convertToColumn(header, iaqSelectAttributes[header]);
          }
        })
        const file = await getXlsxAsBlob(iaqRows, headers, columns, "Calibration Data");
        const tempUrl = window.URL.createObjectURL(file);
        const fromStr = moment(from).format("YYYYMMDDHHmm");
        const iaqFileName = `EVQsense-${fromStr}.xlsx`;
        setIAQFileName(iaqFileName);
        setIAQTempUrl(tempUrl);
      } else {
        setIAQExport(false);
        handleApiFailureWithDialog(props.requestDialog, exportDataApiResult);
      }
    } else {
      setIAQExport(false);
    }
  }

  const exportEMData = async () => {
    let querys = [];
    querys.push(`from=${from.getTime()}`);
    querys.push(`to=${to.getTime()}`);
    querys.push(`devices=${isCheck}`);

    const options = {
      responseType: 'blob',
    };

    let exportDataApiResult = await api('get', `${envars.telemetryServiceUrl}/telemetry/csv/em?${querys.join('&')}`, null, options);
    if (exportDataApiResult.data && exportDataApiResult.data.type === 'text/csv' && exportDataApiResult.data.size > 62) {
      const disposition = exportDataApiResult.headers['content-disposition'];
      if (disposition && disposition.match(/attachment/)) {
        let filename = disposition.replace(/attachment;.*filename=/, '').replace(/"/g, '');
        const file = new Blob([exportDataApiResult.data]);
        const tempUrl = window.URL.createObjectURL(file);
        setEMFileName(filename);
        setEMTempUrl(tempUrl);
      }
    } else {
      setIEQExport(false);
      const reader = new FileReader();
      reader.readAsText(exportDataApiResult.data)
      reader.onload = () => {
        // let errorResult = {}
        // errorResult.data = JSON.parse(reader.result);
        // handleApiFailureWithDialog(props.requestDialog, errorResult);
      }
    }
  }


  const FileItem = (fileName, url) => {
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={fileName}
        />
        <ListItemSecondaryAction>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }}>
            <GetAppIcon />
          </Button>
        </ListItemSecondaryAction>
      </ListItem >
    )
  }
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(deviceList.map(li => li.deviceId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
    const { value, checked } = e.target;
    setIsCheck([...isCheck, value]);
    if (!checked) {
      setIsCheckAll(false);
      setIsCheck(isCheck.filter(item => item !== value));
    }
  };

  function deviceTable(deviceList) {
    return (
      <TableBody>
        {deviceList.map((device) => (
          <TableRow>
            <TableCell align="center">
              <label>
                <input type="checkbox" id={deviceList.indexOf(device)} value={device.deviceId}
                  onChange={handleClick} checked={isCheck.includes(device.deviceId)} />
                {deviceList.deviceId}
              </label>
            </TableCell>
            <TableCell align="center">
              {device.deviceId}
            </TableCell>
            <TableCell align="center">
              {device.location}
            </TableCell>
            <TableCell align="center">
              {device.type}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }


  return (
    <div className="paper-with-padding">
      <Grid container spacing={1} justify="flex-start">
        <Grid item>
          <Typography variant="h4" component="h2">
            Export Calibration Data
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <Paper className="paper-with-padding">
            <Typography variant="h6" component="h2">
              Duration
            </Typography>
            <Grid container spacing={3} alignItems='center'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={4}>
                  <KeyboardDateTimePicker
                    id='start-from'
                    variant="inline"
                    ampm={false}
                    label="Start From"
                    value={from}
                    onChange={setFrom}
                    shouldDisableDate={dateDisabled}
                    format="yyyy/MM/dd HH:mm"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDateTimePicker
                    id='end-to'
                    variant="inline"
                    ampm={false}
                    label="End To"
                    value={to}
                    onChange={setTo}
                    shouldDisableDate={dateDisabled}
                    format="yyyy/MM/dd HH:mm"
                    margin="normal"
                    fullWidth
                  />
                </Grid>

              </MuiPickersUtilsProvider>

              <br />
              {/*<Grid item xs={12} sm={6}>
              <Typography variant="h6" component="h2" justify="flex-start">
              Download file type:&nbsp;&nbsp;
              
              <Typography variant="h6" component="h0" justify="flex-start">
              <input type="checkbox"  id='csv' value='csv' /> .csv &nbsp;&nbsp;
              <input type="checkbox" id='graph' value='graph'/> graphs
              </Typography>
              </Typography>
            </Grid>
            <br/>*/}
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" component="h2" justify="flex-start">
                  Data Frequency: &nbsp;&nbsp;

                  <label style={{ fontSize: 16 }}><input type="checkbox" id='Default' value="Default" checked={display === "Default"} onChange={(e) => { setDisplay(e.target.value) }} /> Default &nbsp;&nbsp;</label>
                  <label style={{ fontSize: 16 }}><input type="checkbox" id='Hourly' value="Hour" checked={display === "Hour"} onChange={(e) => { setDisplay(e.target.value) }} /> Hourly &nbsp;&nbsp;</label>
                  <label style={{ fontSize: 16 }}><input type="checkbox" id='Daily' value="Day" checked={display === "Day"} onChange={(e) => { setDisplay(e.target.value) }} /> Daily &nbsp;&nbsp;</label>
                  <label style={{ fontSize: 16 }}><input type="checkbox" id='Monthly' value="Month" checked={display === "Month"} onChange={(e) => { setDisplay(e.target.value) }} /> Monthly &nbsp;&nbsp;</label>
                  <label style={{ fontSize: 16 }}><input type="checkbox" id='Yearly' value="Year" checked={display === "Year"} onChange={(e) => { setDisplay(e.target.value) }} /> Yearly</label>

                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl required fullWidth>
                  <Typography variant="h6" component="h0" justify="flex-start" gutterBottom>
                    Device ID &nbsp;&nbsp;&nbsp;&nbsp;
                    <label style={{ fontSize: 16 }}><input type="checkbox" id='selectAll' value="selectAll" onChange={handleSelectAll} checked={isCheckAll} />Select All &nbsp;&nbsp;</label>
                    {/*<label><input type="checkbox" id="deselectAll" value="deselectAll" />Deselect all </label>*/}
                  </Typography>
                  <Typography variant="h6" component="h2" >
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <FormControl required fullWidth>
                          <TextField
                            enabled
                            label='DeviceId filter'
                            id='deviceIdFilter'
                            name='deviceIdFilter'
                            values={searchDeviceId}
                            onChange={(e) => { setSearchDeviceId(e.target.value); deviceTable(deviceList) }}
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              )
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl required fullWidth>
                          <TextField
                            enabled
                            label='Location filter'
                            id='locationFilter'
                            name='locationFilter'
                            values={searchLocation}
                            onChange={(e) => { setSearchLocation(e.target.value); deviceTable(deviceList) }}
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              )
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                            Type
                          </InputLabel>
                          <Select
                            value={searchType}
                            onChange={(e) => { setSearchType(e.target.value); deviceTable(deviceList) }}
                            displayEmpty
                            fullWidth
                            inputProps={{
                              name: "type",
                              id: "type-selector"
                            }}
                          >
                            <MenuItem value={''}>None</MenuItem>
                            <MenuItem value={'IAQ'}>IAQ</MenuItem>
                            <MenuItem value={'IEQ'}>IEQ</MenuItem>
                            <MenuItem value={'EM'}>EM</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {isCheck.length > 0 && display !== null ?
                        <Grid item xs={12} sm={3}>
                          <Grid container justify="flex-end">
                            <Button
                              onClick={exportButtonClick}
                              color="secondary"
                              variant="contained"
                            >
                              <DescriptionIcon />
                              Export
                            </Button>
                          </Grid>
                        </Grid>
                        : null}
                    </Grid>
                  </Typography>
                  {exportFileClick ? (
                    <Grid container spacing={4}>
                      <Grid item xs>
                        <Paper className="paper-with-padding">
                          <Grid item xs>
                            <Typography variant="body1" component="h2">
                              <SubjectIcon />
                            </Typography>
                          </Grid>
                          <Grid container spacing={2}>
                            {filePreparing ? (
                              <Grid item xs={12}>
                                <PageLoadingView />
                              </Grid>
                            ) : (
                              <Grid item xs={12}>
                                <List>
                                  {IAQExport ?
                                    <Grid item xs={12}>
                                      {FileItem(IAQFileName, IAQTempUrl)}
                                    </Grid>
                                    : null}
                                  {IEQExport ?
                                    <Grid item xs={12}>
                                      {FileItem(EMFileName, EMTempUrl)}
                                    </Grid>
                                    : null}
                                  {!IAQExport && !IEQExport ?
                                    <Grid item xs={12}>
                                      <Typography>
                                        No data found for given period.
                                      </Typography>
                                    </Grid>
                                    : null}
                                </List>
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  ) : null}
                  <TableContainer component={Paper}>
                    <Table xs={12} sm={12} >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Checkbox</TableCell>
                          <TableCell align="center">Device ID</TableCell>
                          <TableCell align="center">Location</TableCell>
                          <TableCell align="center">Type</TableCell>
                        </TableRow>
                      </TableHead>
                      {!loaded ? (<PageLoadingView />) : (deviceTable(deviceList))}
                    </Table>
                  </TableContainer>
                  {/*<FormControlLabel control={<Checkbox defaultChecked />} label="Label" />*/}
                </FormControl>
              </Grid>

              <br />

            </Grid>


          </Paper>
        </Grid>
      </Grid>
      <br /><br />





    </div>

  )
}

const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExportDataPage)));
